import React, { useState, useEffect } from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { Link, useParams, useNavigate } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ReactTooltip from 'react-tooltip';
import { getNftDetailsAction, nftLikeDislikeAction, getNftHistoryAction, buyItemAction, bidPlaceAPIAction, validatewhitelistedwalletAction, getWhiteListONOFFAction, buyBNBItemAction, getlicensemetadataAction,  addToCartAction,getlandmetadataAction, getlandroadsAction, getcartcountAction, getSettingsAction } from '../Action/user.action';
import config from '../config';
import Cookies from 'js-cookie';
import Web3 from 'web3';
import Swal from "sweetalert2";
import toast, { Toaster } from 'react-hot-toast';
import DamageRating from '../components/layouts/DamageRating';
import AccuracyRating from '../components/layouts/AccuracyRating';
import RateRating from '../components/layouts/RateRating';
const loginData = (!Cookies.get('loginSuccessSinverseUserpanel')) ? [] : JSON.parse(Cookies.get('loginSuccessSinverseUserpanel'));


const NFTDetails = () => {
    const { id } = useParams();
    const [cartcount, setcartcount] = useState(0);
     const [settings, setSettings] = useState([]);
    const [NFTDetails, setNFTDetails] = useState([]);
    const [NFTHistory, setNFTHistory] = useState([]);
    const [NFTMetadata, setNFTMetaData] = useState([]);
    const [IsWhitelistOnOff, getWhiteListONOFF] = useState([]);
    const [connectWalletAddress, setConnectWalletAddress] = useState('');
    const [spinLoader, setSpinLoader] = useState(0);
    const [isHistory, setHistory] = useState(0);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [modalopen, setModalopen] = useState(0);
    const [Biderror, setBiderror] = useState(0);
    const [ErrorMessage, setErrorMessage] = useState('');
    const [Landroads, setLandRoads] = useState([]);  
    const [loader, setloader] = useState(0);  
    const [royalty_fee, setroyaltyFee] = useState('');  
    const [lastVisitedPage, setLastVisitedPage] = useState('');
    const [form, setForm] = useState({
        'bid_price': ''
    })
   
    const navigate = useNavigate();
	  const handleGoBack = () => {
		navigate(-1); // Navigate back by one step in the history stack
		
	  };    
 
    useEffect(async () => {
		const slastVisitedPage = localStorage.getItem('lastVisitedPage');	
		if (slastVisitedPage != null && slastVisitedPage != 'null' && slastVisitedPage != 'undefined') {
			setLastVisitedPage(slastVisitedPage);
		}
    if (window.ethereum) {
            window.ethereum.on('accountsChanged', function (accounts) {
                 if (accounts) {
                    setConnectWalletAddress(accounts[0]);   
                    getcartcountAPI(accounts[0]);                        
                }
            })
        }
    setTimeout(() => {
		if (window.ethereum) {			
			const { ethereum } = window;
			setConnectWalletAddress(ethereum.selectedAddress);	
			getcartcountAPI(ethereum.selectedAddress);			
		} 
	}, 100);    
        
	getNFTDetailsAPI();
	getNftHistoryAPI();
	getWhiteListONOFFAPI();	 
	getLandRoadsAPI();  
	getSettingsAPI(); 
	const hash = window.location.hash.substring(1);
	if(hash =='history')
	{
		setHistory(1);
	}		
    }, []);
	
	const getcartcountAPI = async (wallet) => {
        let res = await getcartcountAction({ 'user_id': loginData?.id, 'walletAddress' : wallet });
        if (res.success) {
            setcartcount(res.data);
        }
    }
    
    const getNFTDetailsAPI = async () => {
		setloader(1)
        let res = await getNftDetailsAction({ 'id': id, 'user_id': loginData?.id });
        if (res.success) {
            setNFTDetails(res.data); 
            const royaltyPercent = res.data.royalty_percentage || 0;
            const price = res.data.price || 0; 
			const result = parseFloat((royaltyPercent / 100) * price).toLocaleString('en-US');
			setroyaltyFee(result)  
            if(res.data.nft_type==config.LAND)
			{
				let jsonData = await getlandmetadataAction({ 'token_id': res.data.token_id,'contractAddress':res.data.contractAddress});
				setNFTMetaData(jsonData.data.attributes);
			}
			else if(res.data.nft_type==config.LICENSE)
			{
				let jsonData = await getlicensemetadataAction({ 'token_id': res.data.token_id,'contractAddress':res.data.contractAddress});
				setNFTMetaData(jsonData.data.attributes);
			}
			setTimeout(() => {
							setloader(0);
						}, 1000);
			            
        }
    }
    
    const getLandRoadsAPI = async () => {
        let res = await getlandroadsAction();
        if (res.success) {
           setLandRoads(res.data);            
        }
        else
        {
			setLandRoads([])
		}
    }

    const getNftHistoryAPI = async () => {
        let res = await getNftHistoryAction({ 'id': id, 'walletAddress' : connectWalletAddress  });
        if (res.success) {
            setNFTHistory(res.data);
        }
    }
    
    const ShowHistory = () => {
        setHistory(1);
    }
    const HideHistory = () => {
        setHistory(0);
    }
    
     const ShowPlotDetails = () => {
		var Owner_Plots		= [];
		let cell			= parseInt(NFTDetails?.cell_number)-1; 
		const size 			= 274;
		const hsize 		= 149;		
		const canvas 		= document.getElementById('myCanvas');
		canvas.width 		= 1249;
		canvas.height 		= 688;
		const ctx 			= canvas.getContext("2d");
		const tileWSize 	= canvas.width / size;
		const tileHSize 	= canvas.height / hsize;
		let lastTile 		= -1;
		for (let y = 0; y < canvas.height / tileHSize; y++) {
			for (let x = 0; x < canvas.width / tileWSize; x++) {
			const parity = (x + y) % 2;
			const tileNum = x + canvas.width / tileWSize * y;
			const xx = x * tileWSize;
			const yy = y * tileHSize;     
			if(parseInt(tileNum)	=== parseInt(cell))  
			{  	ctx.strokeStyle 	= "#35c8ff"; 
				ctx.globalAlpha = 1;
				ctx.strokeRect(xx, yy, tileWSize, tileHSize);
				ctx.fillStyle = "#dc3545";
				ctx.fillRect(x*tileWSize,y*tileHSize,tileWSize,tileHSize);				
			}
			else if(Landroads.indexOf(tileNum) !== -1)  
			{  	ctx.strokeStyle 	= "#35c8ff"; 
				ctx.globalAlpha = 0.5;
				ctx.strokeRect(xx, yy, tileWSize, tileHSize);
				ctx.fillStyle = "#000000";
				ctx.fillRect(x*tileWSize,y*tileHSize,tileWSize,tileHSize);
			} 				
			else
			{
				ctx.strokeStyle 	= "#35c8ff"; 
				ctx.globalAlpha = 0.5;
				ctx.strokeRect(xx, yy, tileWSize, tileHSize);
			}
					
			  
			}
		  }

			ctx.lineWidth = .1;
			ctx.stroke(); 
			
				 
        setModalopen(1);
        
    }
    
    const getSettingsAPI = async () => {
        let res = await getSettingsAction();
        if (res.success) {
            setSettings(res.data);
        }
    }

    
    const getWhiteListONOFFAPI = async () => {
        let res = await getWhiteListONOFFAction({ 'id': id });
        if (res.success) {
            getWhiteListONOFF(res.data);
        }
    }

    const nftLike = async (type, item_id) => {
        let res = await nftLikeDislikeAction({ 'type': type, 'item_id': item_id, 'walletAddress' : connectWalletAddress });
        if (res.success) {
            getNFTDetailsLikeAPI();
        }
    }
    
     const getNFTDetailsLikeAPI = async () => {
		//setloader(1)
        let res = await getNftDetailsAction({ 'id': id, 'user_id': loginData?.id });
        if (res.success) {
            setNFTDetails(res.data);   
            if(res.data.nft_type==config.LAND)
			{
				let jsonData = await getlandmetadataAction({ 'token_id': res.data.token_id,'contractAddress':res.data.contractAddress});
				setNFTMetaData(jsonData.data.attributes);
			}
			else if(res.data.nft_type==config.LICENSE)
			{
				let jsonData = await getlicensemetadataAction({ 'token_id': res.data.token_id,'contractAddress':res.data.contractAddress});
				setNFTMetaData(jsonData.data.attributes);
			}	
			            
        }
    }
    
   
    
    const addtocart  = async (item_id) => {        
      
        if (!loginData?.id) {           
            Swal.fire({
			  text: 'Please login first!!',
			  title: "Alert",
			  confirmButtonText: 'Okay',
			  confirmButtonColor: '#e7275a',
			  background: '#343444',
			  color: '#FFF',
			  allowOutsideClick:false
			});
        } 
        else {  
            
             if (window.ethereum) {
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                let web3 = new Web3(window.ethereum);
                let currentNetwork = web3.currentProvider.chainId;
                let chainId = config.chainId;
                if (currentNetwork !== chainId) {
                 Swal.fire({
					  text: 'Please select BNB network!',
					  title: "Alert",
					  confirmButtonText: 'Okay',
					  confirmButtonColor: '#e7275a',
					  background: '#343444',
					  color: '#FFF',
					  allowOutsideClick:false
					});
                   
                    return false;
                }
                try {
		
					setSpinLoader(1);
                    setDialogOpen(true);   
                    let tx_builderForToken = '';
					let from_address = accounts[0];
                    if(IsWhitelistOnOff==1){
                        let res = await validatewhitelistedwalletAction({ 'wallet': from_address, 'nft_id':item_id });
                        if (res.success) {                
                            let res = await addToCartAction({ 'item_id': item_id ,'walletAddress': from_address, 'is_group': 0 });
							Swal.fire({
							  text: res.msg,
							  title: "Alert",
							  confirmButtonText: 'Okay',
							  confirmButtonColor: '#e7275a',
							  background: '#343444',
							  color: '#FFF',
							  allowOutsideClick:false
							});
                          
                            setSpinLoader(0);
							setDialogOpen(false);   
							getcartcountAPI(from_address);   
                        }
                        else
                        {
                                setSpinLoader(0);
                                setDialogOpen(false);
                                 Swal.fire({
								  text: res.msg,
								  title: "Alert",
								  confirmButtonText: 'Okay',
								  confirmButtonColor: '#e7275a',
								  background: '#343444',
								  color: '#FFF',
								  allowOutsideClick:false
								});
                                return false;
                        }
                    }
                    else
                    {
                          let res = await addToCartAction({ 'item_id': item_id,'walletAddress': from_address, 'is_group': 0 });
                            Swal.fire({
							  text: res.msg,
							  title: "Alert",
							  confirmButtonText: 'Okay',
							  confirmButtonColor: '#e7275a',
							  background: '#343444',
							  color: '#FFF',
							  allowOutsideClick:false
							});
                             setSpinLoader(0);
							setDialogOpen(false);  
							getcartcountAPI(from_address);     
                    }
                }
                catch (error) {
                    setSpinLoader(0);
                    setDialogOpen(false);
                     Swal.fire({
							  text: 'Something went wrong please try again.',
							  title: "Alert",
							  confirmButtonText: 'Okay',
							  confirmButtonColor: '#e7275a',
							  background: '#343444',
							  color: '#FFF',
							  allowOutsideClick:false
							});
                   
                    return false;
                }
             }
             else {
				Swal.fire({
						  text: 'Please Connect to MetaMask.',
						  title: "Alert",
						  confirmButtonText: 'Okay',
						  confirmButtonColor: '#e7275a',
						  background: '#343444',
						  color: '#FFF',
						  allowOutsideClick:false
						});
                return false;
            }
         }
    }

    const connectMetasmask = async () => {
        if (window.ethereum) {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            setConnectWalletAddress(accounts);
        }
        else {
            toast.error(`Please use dApp browser to connect wallet!`);
        }
    }

    const purchaseItem = async () => {
        if (!loginData?.id) {
            toast.error('Please login first!!');
        } else {
            let tokenId = NFTDetails.token_id;
            let id = NFTDetails.id;
            let tokenPrice = NFTDetails.price;
            if (window.ethereum) {
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                let web3 = new Web3(window.ethereum);
                let currentNetwork = web3.currentProvider.chainId;
                let chainId = config.chainId;
                if (currentNetwork !== chainId) {
                    toast.error('Please select BNB network!');
                    return false;
                }
                try {
					let tx_builderForToken = '';
					let from_address = accounts[0];
					if(IsWhitelistOnOff==1){
						let res = await validatewhitelistedwalletAction({ 'wallet': from_address, 'nft_id':id });
						if (res.success) {
							// Approve token						
							//let tokenValue = parseInt(parseFloat(tokenPrice) * (10 ** 18)).toString();
							let tokenValue = web3.utils.toWei(tokenPrice.toString(), 'ether')
							let tokenContractAddress = `${config.tokenContract}`;

							const tokenContract = await new web3.eth.Contract(config.abiToken, tokenContractAddress);
							let allowance = await tokenContract.methods.allowance(from_address, config.marketplaceContract).call();
							if (parseInt(allowance) < parseInt(tokenValue)) {
								tx_builderForToken = await tokenContract.methods.approve(config.marketplaceContract.toString(), tokenValue.toString());
								
								setSpinLoader(1);
								setDialogOpen(true);

								let encodedTxForToken = tx_builderForToken.encodeABI();
								let gasPriceForToken = await web3.eth.getGasPrice();

								let gasLimitForToken = await web3.eth.estimateGas({
									gasPrice: web3.utils.toHex(gasPriceForToken),
									to: tokenContractAddress,
									from: from_address,
									chainId: chainId,
									data: encodedTxForToken
								});

								await web3.eth.sendTransaction({
									gasPrice: web3.utils.toHex(gasPriceForToken),
									gas: web3.utils.toHex(gasLimitForToken),
									to: tokenContractAddress,
									from: from_address,
									chainId: chainId,
									data: encodedTxForToken
								});
							}

							let getTokenBalance = await tokenContract.methods.balanceOf(from_address.toString()).call();
							var currentBal = parseInt(getTokenBalance) / 100000000;
							if (currentBal < NFTDetails.price) {
								setSpinLoader(0);
								setDialogOpen(false);                        
								toast.error(`Insufficient fund for transfer`);
								return false;
							}
							await trnasferNFT(tokenId, tokenValue);
						}
						else
						{
							setSpinLoader(0);
							setDialogOpen(false);
							toast.error(res.msg);
							return false;
						}
					}
					else
					{
						// Approve token	
							console.log(tokenPrice)					
							//let tokenValue = parseInt(parseFloat(tokenPrice) * (10 ** 18)).toString();
							let tokenValue = web3.utils.toWei(tokenPrice.toString(), 'ether')
							let tokenContractAddress = `${config.tokenContract}`;
							console.log(tokenValue)	
							const tokenContract = await new web3.eth.Contract(config.abiToken, tokenContractAddress);
							let allowance = await tokenContract.methods.allowance(from_address, config.marketplaceContract).call();
							if (parseInt(allowance) < parseInt(tokenValue)) {
								tx_builderForToken = await tokenContract.methods.approve(config.marketplaceContract.toString(), tokenValue.toString());
								
								setSpinLoader(1);
								setDialogOpen(true);

								let encodedTxForToken = tx_builderForToken.encodeABI();
								let gasPriceForToken = await web3.eth.getGasPrice();

								let gasLimitForToken = await web3.eth.estimateGas({
									gasPrice: web3.utils.toHex(gasPriceForToken),
									to: tokenContractAddress,
									from: from_address,
									chainId: chainId,
									data: encodedTxForToken
								});

								await web3.eth.sendTransaction({
									gasPrice: web3.utils.toHex(gasPriceForToken),
									gas: web3.utils.toHex(gasLimitForToken),
									to: tokenContractAddress,
									from: from_address,
									chainId: chainId,
									data: encodedTxForToken
								});
							}

							let getTokenBalance = await tokenContract.methods.balanceOf(from_address.toString()).call();
							console.log(getTokenBalance);
							var currentBal = parseInt(getTokenBalance) / 100000000;
							console.log(currentBal,'currbal');
							console.log(NFTDetails.price,'nftprice');
							if (currentBal < NFTDetails.price) {
								setSpinLoader(0);
								setDialogOpen(false);                        
								toast.error(`Insufficient fund for transfer`);
								return false;
							}
							await trnasferNFT(tokenId, tokenValue);
					}

                    
                } catch (error) {
                    setSpinLoader(0);
                    setDialogOpen(false);
                    toast.error('Something went wrong please try again.');
                    return false;
                }
            } else {
                toast.error('Please Connect to MetaMask.');
                return false;
            }
        }
    }

    const trnasferNFT = async (tokenId, tokenPrice) => {
        if (window.ethereum) {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            let web3 = new Web3(window.ethereum);
            try {
                let contractAddress = `${config.marketplaceContract}`;
                let from_address = accounts[0];
                const contract = await new web3.eth.Contract(config.abiMarketplace, contractAddress);
                let tx_builder = '';
                let itemPrice = 0;
                itemPrice = tokenPrice / 10 ** 18;
                
                tx_builder = await contract.methods.buy(tokenId.toString(), tokenPrice.toString());
                let encoded_tx = tx_builder.encodeABI();
                let gasPrice = await web3.eth.getGasPrice();

                let gasLimit = await web3.eth.estimateGas({
                    gasPrice: web3.utils.toHex(gasPrice),
                    to: contractAddress,
                    from: from_address,
                    data: encoded_tx
                });

                const txData = await web3.eth.sendTransaction({
                    gasPrice: web3.utils.toHex(gasPrice),
                    gasLimit: web3.utils.toHex(gasLimit),
                    to: contractAddress,
                    from: from_address,
                    data: encoded_tx
                });

                if (txData.transactionHash) {
                    var paymentArr = {
                        email: loginData?.email,
                        user_id: loginData?.id,
                        txHash: txData.transactionHash,
                        amount: itemPrice,
                        to_address: from_address,
                        item_id: NFTDetails?.id
                    }
                    console.log(paymentArr);
                    buyItemAPI(paymentArr)
                } else {
                    toast.error('Something went wrong please try again3.');
                    setSpinLoader(0);
                    setDialogOpen(false);
                    return false;
                }

            } catch (err) {
                console.log(err);
                if (err.message.toString().split('insufficient funds')[1]) {
                    toast.error('Transaction reverted : ' + err.message)
                } else {
                    if (err.toString().split('execution reverted:')[1]) {
                        toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])
                    } else {
                        toast.error(err.message);
                    }
                }
                setSpinLoader(0);
                setDialogOpen(false);
                return false;
            }

        } else {
            toast.error('Please Connect to MetaMask.');
            setSpinLoader(0);
            setDialogOpen(false);
            return false;
        }
    }

    const buyItemAPI = async (data) => {
        let res = await buyItemAction(data);
        if (res.success) {
            toast.success(res.msg);
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } else {
            toast.error(`Something went wrong! Please try again.`);
        }
    }

    const bidPlaced = async () => {
        if (!loginData?.id) {
            toast.error('Please login first!!');
        } else {
            let tokenId = NFTDetails.token_id;
            let tokenPrice = parseFloat(form?.bid_price).toFixed(6);
            if ((parseFloat(NFTDetails?.max_bid).toFixed(6)) >= tokenPrice) {
                setBiderror(1);
                setErrorMessage('Bid amount should be higher than max bid amount!!');
                return false;
            }

            setBiderror(0);
            setErrorMessage('');

            if (window.ethereum) {
                let web3 = new Web3(window.ethereum);
                let currentNetwork = web3.currentProvider.chainId;

                let chainId = config.chainId;
                if (currentNetwork !== chainId) {
                    toast.error('Please select BNB network!');
                    return false;
                }

                try {
                    setSpinLoader(1);
                    setDialogOpen(true);
                    setModalopen(0);
                    tokenPrice = parseInt((parseFloat(tokenPrice)) * 10 ** 18);
                    await placeBidNow(tokenId, tokenPrice);
                } catch (error) {
                    toast.error('Something went wrong please try again2.');
                    setSpinLoader(0);
                    setDialogOpen(false);
                    setModalopen(1);
                    return false;
                }
            } else {
                toast.error('Please Connect to MetaMask.');
                setSpinLoader(0);
                setDialogOpen(false);
                setModalopen(1);
                return false;
            }
        }
    }
        
    const purchaseItemInBNB = async () => {
        if (!loginData?.id) {
            toast.error('Please login first!!');
        } else {
            let tokenId = NFTDetails.token_id;
            let tokenPrice = NFTDetails.bnb_price;
            if (window.ethereum) {
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                let web3 = new Web3(window.ethereum);
                let currentNetwork = web3.currentProvider.chainId;
                let chainId = config.chainId;
                if (currentNetwork !== chainId) {
                    toast.error('Please select BNB network!');
                    return false;
                }
                try {
                    tokenPrice = parseInt((parseFloat(tokenPrice)) * 10 ** 18);
                    let from_address = accounts[0];
                    var getBalace = await web3.eth.getBalance(from_address) / (10 ** 18);
                    var currentBal = parseFloat(getBalace).toFixed(6)
                    if (currentBal < NFTDetails.bnb_price) {
                        toast.error(`Insufficient fund for transfer`);
                        return false;
                    }

                    setSpinLoader(1);
                    setDialogOpen(true);

                    await trnasferNFTInBNB(tokenId, tokenPrice);
                } catch (error) {
                    toast.error('Something went wrong please try again2.');
                    this.setState({
                        spinLoader: 0,
                        isDialogOpen: false
                    })
                    return false;
                }
            } else {
                toast.error('Please Connect to MetaMask.');
                this.setState({
                    spinLoader: '0',
                    isDialogOpen: false
                })
                return false;
            }
        }
    }

    const trnasferNFTInBNB = async (tokenId, tokenPrice) => {
        if (window.ethereum) {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            let web3 = new Web3(window.ethereum);
            var chainId = web3.currentProvider.chainId;
            try {

                let contractAddress = `${config.marketplaceContract}`
                let from_address = accounts[0];

                const contract = await new web3.eth.Contract(config.abiMarketplace, contractAddress);
                let tx_builder = '';
                let cryptoAmount = tokenPrice;
                let itemPrice = 0;

                itemPrice = tokenPrice / 10 ** 18;
                tx_builder = await contract.methods.buyBNB(tokenId.toString());

                let encoded_tx = tx_builder.encodeABI();
                let gasPrice = await web3.eth.getGasPrice();

                let gasLimit = await web3.eth.estimateGas({
                    gasPrice: web3.utils.toHex(gasPrice),
                    to: contractAddress,
                    from: from_address,
                    chainId: chainId,
                    value: web3.utils.toHex(cryptoAmount),
                    data: encoded_tx
                });

                const txData = await web3.eth.sendTransaction({
                    gasPrice: web3.utils.toHex(gasPrice),
                    gas: web3.utils.toHex(gasLimit),
                    to: contractAddress,
                    from: from_address,
                    chainId: chainId,
                    value: web3.utils.toHex(cryptoAmount),
                    data: encoded_tx
                });

                if (txData.transactionHash) {
                    var paymentArr = {
                        email: loginData?.email,
                        user_id: loginData?.id,
                        txHash: txData.transactionHash,
                        amount: itemPrice,
                        to_address: from_address,
                        item_id: NFTDetails?.id
                    }
                    console.log(paymentArr);
                    buyBNBItemAPI(paymentArr)
                } else {
                    toast.error('Something went wrong please try again3.');
                    setSpinLoader(0);
                    setDialogOpen(false);
                    return false;
                }

            } catch (err) {
                if (err.message.toString().split('insufficient funds')[1]) {
                    toast.error('Transaction reverted : ' + err.message)
                } else {
                    if (err.toString().split('execution reverted:')[1]) {
                        toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])
                    } else {
                        toast.error(err.message);
                    }
                }
                setSpinLoader(0);
                setDialogOpen(false);
                return false;
            }

        } else {
            toast.error('Please Connect to MetaMask.');
            setSpinLoader(0);
            setDialogOpen(false);
            return false;
        }
    }

    const buyBNBItemAPI = async (data) => {
        let res = await buyBNBItemAction(data);
        if (res.success) {
            toast.success(res.msg);
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } else {
            toast.error(`Something went wrong! Please try again.`);
        }
    }

    const placeBidNow = async (tokenId, tokenPrice) => {
        if (window.ethereum) {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            let web3 = new Web3(window.ethereum);
            var chainId = config.chainId;
            try {

                let contractAddress = `${config.marketplaceContract}`
                let from_address = accounts[0];

                const contract = await new web3.eth.Contract(config.abiMarketplace, contractAddress);
                let tx_builder = '';
                tx_builder = await contract.methods.placeBid(tokenId.toString());

                let encoded_tx = tx_builder.encodeABI();
                let gasPrice = await web3.eth.getGasPrice();

                let gasLimit = await web3.eth.estimateGas({
                    gasPrice: web3.utils.toHex(gasPrice),
                    to: contractAddress,
                    from: from_address,
                    chainId: chainId,
                    value: web3.utils.toHex(tokenPrice),
                    data: encoded_tx
                });

                const txData = await web3.eth.sendTransaction({
                    gasPrice: web3.utils.toHex(gasPrice),
                    gas: web3.utils.toHex(gasLimit),
                    to: contractAddress,
                    from: from_address,
                    chainId: chainId,
                    value: web3.utils.toHex(tokenPrice),
                    data: encoded_tx
                });

                if (txData.transactionHash) {
                    var paymentArr = {
                        "email": loginData?.email,
                        "bid_price": parseFloat(form?.bid_price).toFixed(6),
                        "user_id": loginData?.id,
                        "item_id": NFTDetails?.id,
                        "owner_id": NFTDetails?.owner_id,
                        "txhash": txData.transactionHash
                    }
                    bidPlaceAPI(paymentArr)
                } else {
                    toast.error('Something went wrong please try again3.');
                    setSpinLoader(0);
                    setDialogOpen(false);
                    setModalopen(1);
                    return false;
                }

            } catch (err) {
                if (err.message.toString().split('insufficient funds')[1]) {
                    toast.error('Transaction reverted : ' + err.message)
                } else {
                    if (err.toString().split('execution reverted:')[1]) {
                        toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])
                    } else {
                        toast.error(err.message);
                    }
                }
                setSpinLoader(0);
                setDialogOpen(false);
                setModalopen(1);
                return false;
            }
        } else {
            toast.error('Please Connect to MetaMask.');
            setSpinLoader(0);
            setDialogOpen(false);
            setModalopen(1);
            return false;
        }
    }

    const bidPlaceAPI = async (paymentArr) => {
        let res = await bidPlaceAPIAction(paymentArr);
        if (res.success) {
            toast.success(res.msg);
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } else {
            toast.error(`Something went wrong! Please try again.`);
        }
    }

    const bidItem = async () => {
        if (!loginData?.id) {
            toast.error('Please login first!!');
        }
        setModalopen(1);
    }

    const closebutton = async () => {
        setModalopen(0);
    }

    const inputHandler = (e) => {
        const { name, value } = e.target
        setForm({ ...form, [name]: value })
    }
	
	return (
        <div className='item-details'>
            <Toaster />
            <Header data={{'cartcount':cartcount}} />
            <div className="market-place-banner">
			  <h1>NFT Details</h1>
			</div>
            <div className="market-place pro-view-page">
                <div className="container">
                <div className="row">
                <div className="col-12 col-md-3 col-lg-2 px-0 ">
                    <div className="left-sidebar">
                    <ul>
                    { lastVisitedPage !='portfolio' ?
						(
						<>
                        <li className={NFTDetails.nft_type==config.WEAPON ? "active" :''}><Link to={`${config.baseUrl}marketplace`} className="">Weapon & Armor Collections</Link></li>
                        <li className={NFTDetails.nft_type==config.LAND ? "active" :''}><Link to={`${config.baseUrl}land-marketplace`} className="">Land</Link></li>
                        <li className={NFTDetails.nft_type==config.WEED ? "active" : NFTDetails.nft_type==config.COFFIEE ? "active":''} ><Link to={`${config.baseUrl}licenses-cities`} className="">License</Link></li>
                        <li className={NFTDetails.nft_type==config.SAFE ? "active" :''}><Link to={`${config.baseUrl}othernfts`} className="">Other NFTs</Link></li>	
                        </>
					)
					:
					(
					<>
					 <li className={NFTDetails.nft_type==config.WEAPON ? "active" :''}><Link to={`${config.baseUrl}portfolio-weapon`} className="">Weapon & Armor Collections</Link></li>
                        <li className={NFTDetails.nft_type==config.LAND ? "active" :''}><Link to={`${config.baseUrl}portfolio-land`} className="">Land</Link></li>
                        <li className={NFTDetails.nft_type==config.WEED ? "active" : NFTDetails.nft_type==config.COFFIEE ? "active":''} ><Link to={`${config.baseUrl}portfolio-license`} className="">License</Link></li>
                        <li className={NFTDetails.nft_type==config.SAFE ? "active" :''}><Link to={`${config.baseUrl}portfolio-other`} className="">Other NFTs</Link></li>	
                        </>
					)}
                        
                        
                       <li><Link to={`${config.baseUrl}mystery-loot-crates`} className="">Mystery Loot Crates</Link></li>									
                    </ul>
                    </div>
                </div>
                <div className="col-12 col-md-9 col-lg-10 pl-4">
                    <div className="row">
                    <div className="col-12 back-btn">
                    <button className="custom-btn btn-bg-blue" onClick={handleGoBack}><i className="fa-solid fa-chevron-left"></i>Back</button>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className={NFTDetails.nft_type==config.WEAPON ? "pro-view pro-view-weapon" : NFTDetails.nft_type==config.LAND ? "pro-view pro-view-land" : "pro-view" }>
                        {NFTDetails.nft_type==config.WEAPON && NFTDetails.category_id != 8 ? 
                        <>
                        <div className="pro-rating-box">
                            <div className="pro-rating">          
                                <p>Damage</p>
                                <div className="pro-rating-icon">
                                <DamageRating rating={NFTDetails?.Damage} />	
                                </div>
                            </div>
                            {NFTDetails.category_id ===2 ? 
								 <>
								 <div className="pro-rating">          
									<p>Weapon reach </p>
									<div className="pro-rating-icon">
									<AccuracyRating rating={NFTDetails?.Weapon_reach} />
									</div>
								</div>								
								<div className="pro-rating">          
									<p>Speed rating </p>
									<div className="pro-rating-icon">
									<RateRating rating={NFTDetails.Speed_Rating} />
									</div>
								</div>								
								</>
								:
								NFTDetails.category_id ===3 || NFTDetails.category_id ===4 ?  
								<>								
								<div className="pro-rating">          
									<p>Accuracy </p>
									<div className="pro-rating-icon">
									<AccuracyRating rating={NFTDetails?.Accuracy} />
									</div>
								</div>
								<div className="pro-rating">          
									<p>Blast radius </p>
									<div className="pro-rating-icon">
									<RateRating rating={NFTDetails.Blast_Radius} />
									</div>
								</div>
								</>
								:
								<>
								<div className="pro-rating">          
									<p>Accuracy </p>
									<div className="pro-rating-icon">
									<AccuracyRating rating={NFTDetails?.Accuracy} />
									</div>
								</div>
								<div className="pro-rating">          
									<p>Rate of fire </p>
									<div className="pro-rating-icon">
									<RateRating rating={NFTDetails.Rate_of_Fire} />
									</div>
								</div>															
								
								</>
								}                        
                            		
                            </div>
                        </>
                            :'' }
                            
                           
                            
                            
                        <div className="pro-view-img">
                       {loader === 0 ? (
                        NFTDetails.nft_type==config.LAND ?
                            <a href="javascript:;" onClick={() => { ShowPlotDetails() }}><img src="images/lant-plot.png" alt="Axies" /></a>
                            :
                            NFTDetails?.image ?
                                <img src={`${config.ipfsUrl + NFTDetails?.image}`} alt="NFT Image" />
                                :
                                <img height="100%" width="100%" src="images/no-image.png" alt="NFT Image" />
                           )
                           : (
								<div className="loaderDiv">
							  <img className='loaderImg' src="images/loader.gif" height={50} width={50} />
							  </div>
							)}
                        
                        </div>
                        </div>
                        {NFTDetails.nft_type==config.LAND ?
                         <p className="map-text"><a href="javascript:;" onClick={() => { ShowPlotDetails() }}>View on map</a></p>
                         : ''
					 }
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="pro-detail-box">
                        <h2 className="title">{NFTDetails?.title}</h2>
                        
                        <div className="btn-group">
                            <button className={isHistory === 0 ? "custom-btn btn-bg-blue active" :"custom-btn btn-bg-blue"} onClick={() => { HideHistory() }}>Info</button>
                            <button className={isHistory === 1 ? "custom-btn btn-bg-blue active" :"custom-btn btn-bg-blue"} onClick={() => { ShowHistory() }}>History</button>
                        </div>
                        <div className="pro-detail-inner">
                        {isHistory === 0 ?  
							<>
                            <div className="pro-detail">
                            <p></p>
                            <p>
                            {loginData?.id ?
                                <span to="#" onClick={() => nftLike(NFTDetails.isLike ? NFTDetails.isLike : '0', NFTDetails.id)} className="wishlist-button">
                                    <i class="fa fa-heart" style={{ color: NFTDetails.isLike ? 'red' : '#fff' }} aria-hidden="true"></i> &nbsp; <span className="number-like">{NFTDetails.itemLike}</span>
                                </span>
                                :
                                
                                <span>
                                    <Link to={`${config.baseUrl}login`} className="wishlist-button heart">
                                        <i class="fa fa-heart" style={{ color: NFTDetails.isLike ? 'red' : '#fff' }} aria-hidden="true"></i> &nbsp;  <span className="number-like">{NFTDetails.itemLike}</span>
                                    </Link>
                                </span>
                            }
                            </p>
                        </div>
                            <div className="pro-detail">
                            <p>Created Date :</p>
                            <p>{NFTDetails?.datetime}</p>
                        </div>
                        <div className="pro-detail">
                            <p>Owned By       :</p>
                            <p>{NFTDetails?.owner_address ? 
								NFTDetails?.owner_address.toString().substring(0, 5) + '...' + NFTDetails?.owner_address.toString().substring(NFTDetails?.owner_address.length - 5) :''}</p>
                        </div>
                        <div className="pro-detail">
                            <p>Created By :</p>
                            <p>{NFTDetails?.creater_name}</p>
                        </div>
                        <div className="pro-detail">
							<p>Contract Address:</p>
							<p>{NFTDetails?.contractAddress ? 
							NFTDetails?.contractAddress.toString().substring(0, 5) + '...' + NFTDetails?.contractAddress.toString().substring(NFTDetails?.contractAddress.length - 5) :''}</p>
						</div>
                        <div className="pro-detail">
                            <p>Token ID         :</p>
                            <p>#{NFTDetails?.token_id}</p>
                        </div>
                        <div className="pro-detail">
                            <p>Royalty          :</p>
                            <p>{NFTDetails?.royalty_percentage}%</p>
                        </div>
                        { NFTDetails.nft_type==config.WEAPON ?							
							settings.is_weapon_on_sale ?
							<>
							 <div className="pro-detail">
								<p>Royalty Fees          :</p>
								<p>{royalty_fee} SIN</p>
							</div>
							</>
							:
							''
							:
							<>
							 <div className="pro-detail">
								<p>Royalty Fees          :</p>
								<p>{royalty_fee} SIN</p>
							</div>
							</>
						}
                         
                        {NFTDetails.nft_type==1 ? 
                        <>
                        <div className="pro-detail">
                            <p>Category        :</p>
                            <p>{NFTDetails?.category_name}</p>
                        </div> 
                        </>                        
                        :'' } 
                         
                                              
                         {NFTDetails.nft_type==config.LAND ||  NFTDetails.nft_type==config.WEED || NFTDetails.nft_type==config.COFFIEE ? 
							 NFTMetadata.length == 0 ? '' :
								NFTMetadata.map((item, index) => (
									<>
									<div className="pro-detail">
										<p>{item.trait_type}        :</p>
										<p>{item.value}</p>
									</div>
									</>
								))
                        :'' }   
                            
                        </>
                        :
                        <>
                        <div className="pro-detail-inner-history">
                        {
							(NFTDetails.nft_type==config.WEAPON && settings.is_weapon_on_sale) || (NFTDetails.category_id==8) || (NFTDetails.nft_type==config.LAND && settings.is_land_on_sale) || ((NFTDetails.nft_type==config.WEED || NFTDetails.nft_type==config.COFFIEE) && settings.is_license_on_sale) || (NFTDetails.nft_type==config.SAFE && settings.is_safe_on_sale) ?
							
							NFTHistory.map((item, index) => (
							<div className="pro-detail">
							<div className="col-sm-8">
								<div className="auther-info-div">
									 <div className="author-avatar">								
									{!item.user_profile || item.user_profile == null || item.user_profile == 'null' ?
										<img src="images/default-user-icon.jpg" alt="Owner Profile" className="avatar" />
										:
										<img src={`${config.imageUrl + item.user_profile}`} alt="Axies" className="avatar" />
									}
									
									</div>
										<div className="author-infor">
											<div className="name">
												<h6>
												{item?.user_address.toString().substring(0, 3) + '...' + item?.user_address.toString().substring(item?.user_address.length - 3)}
												</h6> <span> {item.transaction_type}</span>
											</div>
											<span className="time">{item.created_date}</span>
										</div>	
								</div>
								</div>
								<div className="col-sm-4 price text-right">
									<span> {parseFloat(item.amount).toLocaleString('en-US')} SIN</span>
								</div>
							</div>
							))
							:
							<>
								<div className="nha">
									<h6>No history available</h6>
								</div>
							</>
						}
						</div>
                        </>
					}
                        </div>
                        
                       { (NFTDetails.nft_type==config.WEAPON && settings.is_weapon_on_sale) || (NFTDetails.nft_type==config.LAND && settings.is_land_on_sale) || ((NFTDetails.nft_type==config.WEED || NFTDetails.nft_type==config.COFFIEE) && settings.is_license_on_sale) || (NFTDetails.nft_type==config.SAFE && settings.is_safe_on_sale) ?
								<>
                       
                        <div className="price-box">
                            <p><span>Price</span> <br />{parseInt(NFTDetails?.price).toLocaleString('en-US')} SIN </p>
                             {connectWalletAddress ?
								connectWalletAddress == NFTDetails.owner_address && NFTDetails.is_sold == 0 ?
                                <>
                                    <i style={{ fontSize: '25px' }} class="fa fa-exclamation-circle" data-tip={`You are the owner of this NFT`} aria-hidden="true"></i>
                                    <ReactTooltip /> &nbsp;
                                </> : ''
                                : ''
                            }
                            {loginData.id ?
                                connectWalletAddress ?
                                    NFTDetails.is_sold === 1 || NFTDetails?.is_on_sale == 0 ?
                                        <p style={{ color: 'red' }}>Sold Out</p> :
                                        NFTDetails.sell_type === 1 ?
                                        <>                                                       
                                            <button style={{ cursor: NFTDetails.is_sold === 1 || connectWalletAddress == NFTDetails.owner_address ? 'not-allowed' : '', background: NFTDetails.is_sold === 1 || connectWalletAddress == NFTDetails.owner_address ? 'gray' : '', }} disabled={spinLoader || NFTDetails.is_sold === 1 || connectWalletAddress == NFTDetails.owner_address || NFTDetails == 1 || NFTDetails?.is_on_sale == 0 ? true : false} onClick={() => addtocart(NFTDetails.id)} className="custom-btn">{spinLoader == 1 ? 'Processing...' : `Add to cart`}</button>
                                                </>   
                                            :
                                           ''
                                :
                                <button onClick={() => connectMetasmask()} className="custom-btn">Connect Wallet</button>
                                :
                                <a href={`${config.baseUrl}login`}>
                                    <button className="custom-btn">Login</button>
                                </a>
                            }
                            
                        </div>
                        </>
                        : (NFTDetails.nft_type==config.WEAPON && NFTDetails.category_id==8) ?
								<>
                       
                        <div className="price-box">
                            <p><span>Price</span> <br />{parseInt(NFTDetails?.price).toLocaleString('en-US')} SIN </p>
                             {connectWalletAddress ?
								connectWalletAddress == NFTDetails.owner_address && NFTDetails.is_sold == 0 ?
                                <>
                                    <i style={{ fontSize: '25px' }} class="fa fa-exclamation-circle" data-tip={`You are the owner of this NFT`} aria-hidden="true"></i>
                                    <ReactTooltip /> &nbsp;
                                </> : ''
                                : ''
                            }
                            {loginData.id ?
                                connectWalletAddress ?
                                    NFTDetails.is_sold === 1 || NFTDetails?.is_on_sale == 0 ?
                                        <p style={{ color: 'red' }}>Sold Out</p> :
                                        NFTDetails.sell_type === 1 ?
                                        <>                                                       
                                            <button style={{ cursor: NFTDetails.is_sold === 1 || connectWalletAddress == NFTDetails.owner_address ? 'not-allowed' : '', background: NFTDetails.is_sold === 1 || connectWalletAddress == NFTDetails.owner_address ? 'gray' : '', }} disabled={spinLoader || NFTDetails.is_sold === 1 || connectWalletAddress == NFTDetails.owner_address || NFTDetails == 1 || NFTDetails?.is_on_sale == 0 ? true : false} onClick={() => addtocart(NFTDetails.id)} className="custom-btn">{spinLoader == 1 ? 'Processing...' : `Add to cart`}</button>
                                                </>   
                                            :
                                           ''
                                :
                                <button onClick={() => connectMetasmask()} className="custom-btn">Connect Wallet</button>
                                :
                                <a href={`${config.baseUrl}login`}>
                                    <button className="custom-btn">Login</button>
                                </a>
                            }
                            
                        </div>
                        </>
                        :                    
                        
                        <>
                        <div className="price-box">
                         <button className="custom-btn">Coming Soon</button>
                         </div>
                        </>
					}
                        
                        <div className="pro-description-text">
                            <p>Description :</p>
                            {NFTDetails.nft_type==config.LAND ?
                            <>
								<p>In SinVerse, private lands are owned and managed by individual players or entities and can serve a multitude of purposes. Players can build personal structures or conduct business activities on their land, establishing their own personal and economic identities within the game world. Additionally, private lands allow for the creation of specialized spaces tailored to specific activities like housing, farming, or crafting. </p>
                            </>
                            :
                            <>
							<p>{NFTDetails?.description}</p>	
                            </>
                            }
                            
                        </div>
                        </div>
                    </div>


                </div>
                </div>
                </div>
                </div>
                </div>	
                        

            <div id="myModal" className={modalopen == '0' ? "modal fade cart-modal mc-popup" : "mc-popup modal fade cart-modal show"} role="dialog" style={{ background: '0% 0% / cover rgba(6, 6, 6, 0.32)', display: modalopen == '0' ? 'none' : 'block' }}
                data-backdrop="false">
                <div className="modal-dialog modal-dialog-centered gridmodal">
                    <div className="modal-content">
						<button type="button" onClick={closebutton} className="close btnClose" data-dismiss="modal">&times;</button>                                      
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="row">                                        
                                        <div class="graph text-center">
											<canvas id="myCanvas"></canvas>
										</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default NFTDetails;
