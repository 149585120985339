import React, { useEffect, useState } from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { Link } from 'react-router-dom'
import { getNFTListByUserAction, cancelOrderAction, putOnSaleAction, filterUserNFTsAction, getLandListUserAction, getfilterLandListUserAction, getNFTLicenseByUserAction, getfilterLicenseByUserAction, getSafeNFTByUserAction, getfilterSafeNFTByUserAction, getLandgroupDetailsAction, updateLandgroupAction, getLandgroupAllNFTsDetailsAction, getlandroadsAction, getglobalroyalityAction, CreateGroupAction, putgroupItemOnSaleAction,getcartcountAction, updateNFTpriceAction } from '../Action/user.action';
import config from '../config';
import toast, { Toaster } from 'react-hot-toast';
import Web3 from 'web3';
import Cookies from 'js-cookie';
import Modal from 'react-modal';
import DamageRating from '../components/layouts/DamageRating';
import AccuracyRating from '../components/layouts/AccuracyRating';
import RateRating from '../components/layouts/RateRating';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const Portfolio = () => {
    let subtitle;
    const [cartcount, setcartcount] = useState(0);
    const [NFTList, setNFTList] = useState([]);
    const [LandList, setLandList] = useState([]);
    const [FilterNFTList, setFilterNFTList] = useState([]);
    const [LandGroupList, setLandGroupList] = useState([]);
    const [LicenseList, setLicenseList] = useState([]);
    const [SafeNFTList, setSafeNFTList] = useState([]);
    const [isPutonsale, setisPutonsale] = useState(0);
    const [isweapon, setisweapon] = useState(1);
    const [island, setisland] = useState(0);
    const [islicense, setislicense] = useState(0);
    const [issafe, setissafe] = useState(0);
    const [itemDetails, setItemDetails] = useState([]);    
    const [SearchFilter, setSearchFilter] = useState('');
    const [SearchLFilter, setSearchLFilter] = useState('');
    const [SearchLiFilter, setSearchLiFilter] = useState('');
    const [SearchSFilter, setSearchSFilter] = useState('');
    const [spinLoader, setSpinLoader] = useState(0);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [blockchainUpdationType, setblockchainUpdationType] = useState(0);
    const loginData = (!Cookies.get('loginSuccessSinverseUserpanel')) ? [] : JSON.parse(Cookies.get('loginSuccessSinverseUserpanel'));
    const [walletAddress, setwalletAddress] = useState('');
    const [modalopen, setModalopen] = useState(0);
    const [Landroads, setLandRoads] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedContract, setSelectedContract] = useState(null);
    const [textboxes, setTextboxes] = useState([]);
    const [isCreatGroup, setCreatGroup] = useState(0);
    const [validatioError, setvalidatioError] = useState({});  
    const [globalroyality, setglobalroyality] = useState('');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [weaponLoader, setweaponLoader] = useState(0);
    const [landLoader, setlandLoader] = useState(0);
    const [licenseLoader, setlicenseLoader] = useState(0);
    const [safeLoader, setsafeLoader] = useState(0);
    
    useEffect(async () => { 
			getLandRoadsAPI();    
			getglobalroyalityAPI();  
		  if (window.ethereum) {			 
            window.ethereum.on('accountsChanged', function (accounts) {
                getLandListAPI([]);
                 setisland(1); 
                setlandLoader(1)
                if (accounts) {
                    setwalletAddress(accounts[0]);
                    getLandListAPI(accounts[0]);
                    getcartcountAPI(accounts[0]);
                }
            })
        }

        setTimeout(() => {
            if (window.ethereum) {				 
                const { ethereum } = window;
                 setisland(1); 
                 setlandLoader(1)
				 getLandListAPI(ethereum.selectedAddress);
                 setwalletAddress(ethereum.selectedAddress);
                 getcartcountAPI(ethereum.selectedAddress);
            } else {
				
				setisland(1); 
                setlandLoader(1)
                getLandListAPI([]);
            }
        }, 100);
        	
           
		
		
	const handleResize = () => {
		  setWindowWidth(window.innerWidth);
		};

		// Add event listener to track window width changes
		window.addEventListener('resize', handleResize);

	// Cleanup the event listener on component unmount
	return () => {
	  window.removeEventListener('resize', handleResize);
	};
    }, []);
    
     useEffect(async () => { 
		if(selectedItems.length==0)
		{
			updateSelectedContract();
		} 
		 
	 }, [selectedItems]);

    function afterOpenModal() {
        
    }
    
     const getcartcountAPI = async (wallet) => {
        let res = await getcartcountAction({ 'user_id': loginData?.id, 'walletAddress' : wallet });
        if (res.success) {
            setcartcount(res.data);
        }
    }
     const getglobalroyalityAPI = async () => {
        let res = await getglobalroyalityAction();
        if (res.success) {
            setglobalroyality(res.data)
        }
    }
    
    const updateSelectedContract = () => {
		setSelectedContract(null);
	};
    
    
     const groupinputHandler = (e) => {
        const { name, value, id } = e.target
        setGroupNFT({ ...groupDetails, [name]: value })

        if (value != '') {
            setvalidatioError((old) => {
                return { ...old, [id]: '' }
            })
        }
    }
    
     const inputHandler = (e) => {
        const { name, value, id } = e.target
        setItemDetails({ ...itemDetails, [name]: value })

        if (value != '') {
            setvalidatioError((old) => {
                return { ...old, [id]: '' }
            })
        }
    }
    
    const handleOtherContractClick = () => {	
		 toast.error('Please select land from the same smart contract');
	  };

    const [groupDetails, setGroupNFT] = useState({
        title: '',
        description: '',
        price: '0',
        is_on_sale: '',
        created_by: '0',
        owner_id: '0',
        is_sold: '0',
        owner_address: '',       
        contractAddress: ''      
    });
    
    const handleCheckboxChange = (e, item, itemId, contractAddress) => {
		
		if (selectedContract != null)
		{ 
			console.log(selectedContract)
			console.log(contractAddress)
			if(selectedContract.toLowerCase() !== contractAddress.toLowerCase()) {
			  // Warn the user about selecting items from different groups
			  toast.error('Please select land from the same smart contract');
			   e.preventDefault()
			  return;
			}
		}

    let updatedSelection;

    if (selectedItems.some((item) => item.id === itemId && item.contractAddress === contractAddress)) {
      // If item is already selected, remove it from the selection
      updatedSelection = selectedItems.filter(
        (item) => item.id !== itemId || item.contractAddress !== contractAddress
      );     
    } else {
      // If item is not selected, add it to the selection
      updatedSelection = [...selectedItems, { id: item.id, name: item.name, image: item.image, token_id: item.token_id,	contractAddress: item.contractAddress, category_id: '0', royalty_percent: item.royalty_percent, price: item.price, sell_type: item.sell_type, start_date: null,	expiry_date: null, owner_address: item.owner_address, is_minted: item.is_minted, group_id: '0' }];
     
    }
    
   
    setSelectedItems(updatedSelection);
    setSelectedContract(contractAddress.toLowerCase());
   
   
  }; 
	
    const getLandRoadsAPI = async () => {
        let res = await getlandroadsAction();
        if (res.success) {
           setLandRoads(res.data);            
        }
        else
        {
			setLandRoads([])
		}
    }
        
    const EditNFTDetails = async (id) => {
		setTimeout(() => {
			window.location.href = `${config.baseUrl}edit-nft/`+id;
		}, 1000);
	}
    
    const getLandListAPI = async (wallet) => {	   
		let res = await getNFTListByUserAction({'walletAddress' : wallet,'nft_type' :config.LAND });
		if (res.success) {
			setLandList(res.data.data);
			setFilterNFTList(res.data.data);
			setLandGroupList(res.data.groupdata);   	
		}
		setlandLoader(0)
    }

    const putOnSaleModelAPI = async (item) => {
        setisPutonsale(1);
        setItemDetails(item);
    }
    
    const putOnSaleModelNewAPI = async (item) => {
        setCreatGroup(2);
        setisPutonsale(1);
        setItemDetails(item);
    }
    
    
    
    const closebutton = async () => {
        setModalopen(0);
    }

    const showland = async () => {
		setlandLoader(1)
		getLandListAPI(walletAddress)
        setisweapon(0);        
        setisland(1);        
        setislicense(0);        
        setissafe(0);  
        
    }
    const closeModel = async () => {
        setisPutonsale(0);
        setCreatGroup(0);
    }
	const ShowPlotDetails = (cell_number) => {
		let cell			= cell_number-1; 
		var Owner_Plots		= [];
		const size 			= 274;
		const hsize 		= 149;		
		const canvas 		= document.getElementById('myCanvas');
		canvas.width 		= 1249;
		canvas.height 		= 688;
		const ctx 			= canvas.getContext("2d");
		const tileWSize 	= canvas.width / size;
		const tileHSize 	= canvas.height / hsize;
		let lastTile 		= -1;
		for (let y = 0; y < canvas.height / tileHSize; y++) {
			for (let x = 0; x < canvas.width / tileWSize; x++) {
			const parity = (x + y) % 2;
			const tileNum = x + canvas.width / tileWSize * y;
			const xx = x * tileWSize;
			const yy = y * tileHSize;     
			if(parseInt(tileNum)	=== parseInt(cell))  
			{  	ctx.strokeStyle 	= "#35c8ff"; 
				ctx.globalAlpha = 1;
				ctx.strokeRect(xx, yy, tileWSize, tileHSize);
				ctx.fillStyle = "#dc3545";
				ctx.fillRect(x*tileWSize,y*tileHSize,tileWSize,tileHSize);				
			}	
			else if(Landroads.indexOf(tileNum) !== -1)  
			{  	ctx.strokeStyle 	= "#35c8ff"; 
				ctx.globalAlpha = 0.5;
				ctx.strokeRect(xx, yy, tileWSize, tileHSize);
				ctx.fillStyle = "#000000";
				ctx.fillRect(x*tileWSize,y*tileHSize,tileWSize,tileHSize);
			} 			
			else
			{
				ctx.strokeStyle 	= "#35c8ff"; 
				ctx.globalAlpha = 0.5;
				ctx.strokeRect(xx, yy, tileWSize, tileHSize);
			}
					
			  
			}
		  }

			ctx.lineWidth = .1;
			ctx.stroke(); 
			
				 
        setModalopen(1);
        
    }
    const ShowGroupPlotDetails = (cell_numbers) => {
		var Owner_Plots		= [];
		var values			= [];
		values				= cell_numbers.split(',').map(Number);
		Owner_Plots			= values.map((value) => parseInt(value, 10) - 1);
		const size 			= 274;
		const hsize 		= 149;		
		const canvas 		= document.getElementById('myCanvas');
		canvas.width 		= 1249;
		canvas.height 		= 688;
		const ctx 			= canvas.getContext("2d");
		const tileWSize 	= canvas.width / size;
		const tileHSize 	= canvas.height / hsize;
		let lastTile 		= -1;
		for (let y = 0; y < canvas.height / tileHSize; y++) {
			for (let x = 0; x < canvas.width / tileWSize; x++) {
			const parity = (x + y) % 2;
			const tileNum = x + canvas.width / tileWSize * y;
			const xx = x * tileWSize;
			const yy = y * tileHSize;     
			if(Owner_Plots.indexOf(tileNum) !== -1)  			
			{  	ctx.strokeStyle 	= "#35c8ff"; 
				ctx.globalAlpha = 1;
				ctx.strokeRect(xx, yy, tileWSize, tileHSize);
				ctx.fillStyle = "#dc3545";
				ctx.fillRect(x*tileWSize,y*tileHSize,tileWSize,tileHSize);				
			}	
			else if(Landroads.indexOf(tileNum) !== -1)  
			{  	ctx.strokeStyle 	= "#35c8ff"; 
				ctx.globalAlpha = 0.5;
				ctx.strokeRect(xx, yy, tileWSize, tileHSize);
				ctx.fillStyle = "#000000";
				ctx.fillRect(x*tileWSize,y*tileHSize,tileWSize,tileHSize);
			} 			
			else
			{
				ctx.strokeStyle 	= "#35c8ff"; 
				ctx.globalAlpha = 0.5;
				ctx.strokeRect(xx, yy, tileWSize, tileHSize);
			}
					
			  
			}
		  }

			ctx.lineWidth = .1;
			ctx.stroke(); 
			
				 
        setModalopen(1);
        
    }
    function validateNFTPricing() {       
        let priceError = "";       
        const isValidValue = itemDetails.price === '' || (Number(itemDetails.price) > 0 && !isNaN(itemDetails.price));
        
		if (isValidValue === false || itemDetails.price=='' ) {
            priceError = "Price field is required."
        }       
        if (priceError) {
            setvalidatioError({
                priceError
            })
            return false
        } else {
			return true
        }
    }
    
     const UpdateNFTPricing = async (e) => {
        e.preventDefault()
        const isValid = validateNFTPricing();
        if (!isValid) {

        }       
        else {
			let res = await updateNFTpriceAction({id:itemDetails.id,price:itemDetails.price});
			 if (res.success) {
				   approveNFT(itemDetails)									
				
			}
		}
        
    }

   
	
    
    
    const approveNFT = async (itemDetails, cancelType = 0) => {
		 
		if(cancelType==1)
		{
			closeModel();
		}		
       	
			console.log(itemDetails)
			setSpinLoader(1);	
        if (window.ethereum) {
            let web3 = '';
            web3 = new Web3(window.ethereum);
            const accounts = await web3.eth.getAccounts();

            let walletAdd = accounts[0];
            if (!walletAdd) {
                toast.error('Please connect your metamask wallet.');
                return;
            } else if (itemDetails.owner_address && walletAdd.toUpperCase() !== itemDetails.owner_address.toUpperCase()) {
                toast.error(`Please select (${itemDetails?.owner_address.substring(0, 8) + '...' + itemDetails?.owner_address.substr(itemDetails?.owner_address.length - 8)}) address to your metamask wallet.`);
                return;
            }
            let from_address = accounts[0];
            var getBalace = await web3.eth.getBalance(from_address) / (10 ** 18);
            var currentBal = parseFloat(getBalace).toFixed(6)
            if (currentBal == 0) {
                toast.error(`Insufficient fund!!`);
                return false;
            }

            let currentNetwork = await web3.currentProvider.chainId;
            web3.eth.defaultAccount = accounts[0];
            let chainId = config.chainId;
            if (currentNetwork !== chainId) {
                toast.error('Please select BNB smartchain!!');
                return false;
            }
             //  Approve transaction
                const contractForApprove = await new web3.eth.Contract(config.nftContractABI, itemDetails?.contractAddress);
                let isApproved = await contractForApprove.methods.isApprovedForAll(from_address, config.mainMarketplaceContract).call();

                if (isApproved == false) {
					try {
						let tx_builderForToken = await contractForApprove.methods.setApprovalForAll(config.mainMarketplaceContract.toString(), true);

						setSpinLoader(1);
						setDialogOpen(true);		
						let encodedTxForToken = tx_builderForToken.encodeABI();
						let gasPriceForToken = await web3.eth.getGasPrice();

						let gasLimitForToken = await web3.eth.estimateGas({
							gasPrice: web3.utils.toHex(gasPriceForToken),
							to: itemDetails?.contractAddress,
							from: from_address,
							chainId: chainId,
							data: encodedTxForToken
						});

						await web3.eth.sendTransaction({
							gasPrice: web3.utils.toHex(gasPriceForToken),
							gas: web3.utils.toHex(gasLimitForToken),
							to: itemDetails?.contractAddress,
							from: from_address,
							chainId: chainId,
							data: encodedTxForToken
						});
						
                }
                catch (err) {
				   if (err.message.toString().split('insufficient funds')[1]) {
						toast.error('Transaction reverted : ' + err.message)
					} else {
						if (err.toString().split('execution reverted:')[1]) {
							toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])

						} else {
							if (err.toString().split('execution reverted:')[1]) {
								toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])
							} else {
								toast.error(err.message);
							}
						}
					}

					setSpinLoader(0);
					setisPutonsale(0);
					setDialogOpen(false);
					if(itemDetails.nft_type==config.LAND)
					{
						 setTimeout(() => {
							window.location.href = `${config.baseUrl}portfolio-land`;
						}, 3000);
					}
					else
					{
						setTimeout(() => {
							window.location.href = `${config.baseUrl}portfolio-land`;
						}, 3000);
					}
					//return false;
				}
			}		
            setSpinLoader(1);
            setisPutonsale(0);
            setDialogOpen(true);
            
            try {
                let mintFee = 0;
                let SalePrice;
                let start_date = 0;
                let expiry_date = 0;
                let tokenId = itemDetails.token_id;
                let royalty_percent = parseInt(parseInt(itemDetails?.royalty_percent) * 100);

                if (itemDetails.sell_type == 1) {
                    SalePrice = web3.utils.toWei(itemDetails.price.toString(), 'ether');       
                }

                else if (itemDetails.sell_type == 2) {
                    SalePrice = web3.utils.toWei(itemDetails.price.toString(), 'ether');       
                    start_date = Math.round(new Date(itemDetails.start_date).getTime() / 1000);
                    expiry_date = Math.round(new Date(itemDetails.expiry_date).getTime() / 1000);
                }
				
                let contractAddress = `${config.mainMarketplaceContract}`
                const contract = await new web3.eth.Contract(config.mainMarketplaceContractABI, contractAddress);

                let nftContractAddress = `${config.nftContract}`
                const nftContract = await new web3.eth.Contract(config.nftContractABI, nftContractAddress);
				
                if (cancelType == 1) {
                    setblockchainUpdationType(2)
                    await contract.methods.cancelOrder(itemDetails?.contractAddress.toString(), tokenId.toString()).call();
                    var tx_builder = await contract.methods.cancelOrder(itemDetails?.contractAddress.toString(), tokenId.toString());
                } else {
                    setblockchainUpdationType(1)
                    if (itemDetails.is_minted == 1) {
                   
                        await contract.methods.putonSale(tokenId.toString(), SalePrice.toString(), itemDetails?.contractAddress.toString(), itemDetails.sell_type.toString(), start_date.toString(), expiry_date.toString(), royalty_percent.toString() ).call();

                        var tx_builder = await contract.methods.putonSale(tokenId.toString(), SalePrice.toString(), itemDetails?.contractAddress.toString(), itemDetails.sell_type.toString(), start_date.toString(), expiry_date.toString(), royalty_percent.toString() );
                    } else {
                        await nftContract.methods._mint(tokenId.toString()).call();
                        var tx_builder = await nftContract.methods._mint(tokenId.toString());

                        let encoded_tx = tx_builder.encodeABI();
                        let gasPrice = await web3.eth.getGasPrice();
                        gasPrice = parseInt(gasPrice) + parseInt(10000000000);

                        let gasLimit = await web3.eth.estimateGas({
                            gasPrice: web3.utils.toHex(gasPrice),
                            to: itemDetails?.is_minted == 1 ? contractAddress : nftContractAddress,
                            from: from_address,
                            value: web3.utils.toHex(mintFee),
                            chainId: chainId,
                            data: encoded_tx
                        });

                        const txData = await web3.eth.sendTransaction({
                            gasPrice: web3.utils.toHex(gasPrice),
                            gas: web3.utils.toHex(gasLimit),
                            to: itemDetails?.is_minted == 1 ? contractAddress : nftContractAddress,
                            from: from_address,
                            value: web3.utils.toHex(mintFee),
                            chainId: chainId,
                            data: encoded_tx
                        });

                        if (txData.transactionHash) {

                            //  Approve transaction
                            const contractForApprove = await new web3.eth.Contract(config.nftContractABI, config.nftContract);
                            let isApproved = await contractForApprove.methods.isApprovedForAll(from_address, config.mainMarketplaceContract).call();
							
                            if (isApproved == false) {
                                let tx_builderForToken = await contractForApprove.methods.setApprovalForAll(config.mainMarketplaceContract.toString(), true);
							
                                setSpinLoader(1);
                                setDialogOpen(true);

                                let encodedTxForToken = tx_builderForToken.encodeABI();
                                let gasPriceForToken = await web3.eth.getGasPrice();

                                let gasLimitForToken = await web3.eth.estimateGas({
                                    gasPrice: web3.utils.toHex(gasPriceForToken),
                                    to: config.nftContract,
                                    from: from_address,
                                    chainId: chainId,
                                    data: encodedTxForToken
                                });

                                await web3.eth.sendTransaction({
                                    gasPrice: web3.utils.toHex(gasPriceForToken),
                                    gas: web3.utils.toHex(gasLimitForToken),
                                    to: config.nftContract,
                                    from: from_address,
                                    chainId: chainId,
                                    data: encodedTxForToken
                                });
                            }

                            await contract.methods.putonSale(tokenId.toString(), SalePrice.toString(), config.nftContract.toString(), itemDetails.sell_type.toString(), start_date.toString(), expiry_date.toString(), royalty_percent.toString() ).call();

                            var tx_builder = await contract.methods.putonSale(tokenId.toString(), SalePrice.toString(), config.nftContract.toString(), itemDetails.sell_type.toString(), start_date.toString(), expiry_date.toString(), royalty_percent.toString() );
                        }
                    }
                }

                let encoded_tx = tx_builder.encodeABI();
                let gasPrice = await web3.eth.getGasPrice();
                gasPrice = parseInt(gasPrice) + parseInt(10000000000);

                let gasLimit = await web3.eth.estimateGas({
                    gasPrice: web3.utils.toHex(gasPrice),
                    to: contractAddress,
                    from: from_address,
                    value: web3.utils.toHex(mintFee),
                    chainId: chainId,
                    data: encoded_tx
                });

                const txData = await web3.eth.sendTransaction({
                    gasPrice: web3.utils.toHex(gasPrice),
                    gas: web3.utils.toHex(gasLimit),
                    to: contractAddress,
                    from: from_address,
                    value: web3.utils.toHex(mintFee),
                    chainId: chainId,
                    data: encoded_tx
                });

                if (txData.transactionHash) {                
                    let dataArr = {                        
                        "wallet_address": from_address,
                        "user_id": loginData.id,
                        "item_id": itemDetails.id,
                        "token_hash": txData.transactionHash
                    }
                  
                    let res;
                    if (cancelType) {
                        res = await cancelOrderAction(dataArr);
                    } else {
                        res = await putOnSaleAction(dataArr);
                    }
                    if (res.success === true) {
                        setDialogOpen(false);                      
                        toast.success(res.msg);
                        setTimeout(() => {
							window.location.href = `${config.baseUrl}portfolio-land`;
						}, 3000);
                    } else {
                        toast.error(res.msg);
                    }
                } else {
                    toast.error('Something went wrong please try again.');
                    setSpinLoader(0);
                    setisPutonsale(0);
                    setCreatGroup(0);
                    setDialogOpen(false);
                    return false;
                }

            } catch (err) {
               if (err.message.toString().split('insufficient funds')[1]) {
                    toast.error('Transaction reverted : ' + err.message)
                } else {
                    if (err.toString().split('execution reverted:')[1]) {
                        toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])

                    } else {
                        if (err.toString().split('execution reverted:')[1]) {
                            toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])
                        } else {
                            toast.error(err.message);
                        }
                    }
                }

                setSpinLoader(0);
                setisPutonsale(0);
                setCreatGroup(0);
                setDialogOpen(false);
                setTimeout(() => {
					window.location.href = `${config.baseUrl}portfolio-land`;
				}, 3000);
				
            }
        } else {
            toast.error('Please connect your metamask wallet.');
            setSpinLoader(0);
            setisPutonsale(0);
            setCreatGroup(0);
            setDialogOpen(false);
            return false;
        }	
    }
	
    const SetLNameFilter = async (e) => {
	  setlandLoader(1)
      setSearchLFilter(e.target.value); 
      const searchText = e.target.value.toLowerCase();
      if(searchText != null){
		  const result = LandList.filter(data => data.name.toLowerCase().includes(searchText) || data.token_id.includes(searchText)); 
		  setFilterNFTList(result); 
		  setlandLoader(0)   
	  }
	  else
		{
			setFilterNFTList(LandList); 
			setlandLoader(0)   
		}
    }
    
  
 
    
  const handleLKeyPress = async (event) => {
		if(event.key === "Enter"){
			setlandLoader(1)
			if(SearchLFilter != null)
			{
			  const result = LandList.filter(data => data.name.toLowerCase().includes(SearchLFilter) || data.token_id.includes(SearchLFilter)); 
			  setFilterNFTList(result); 
			  setlandLoader(0)   
			}
			else
			{
				setFilterNFTList(LandList); 
				setlandLoader(0)   
			}
     }
 }
 
   
  const NameFilter = async (e) => {
		
		setweaponLoader(1)
        let res = await filterUserNFTsAction({ 'user_id': loginData?.id, 'name': SearchFilter,'walletAddress':walletAddress });  // filter weapons
            if (res.success) {
                if(res.data.length > 0){
                    setNFTList(res.data);
                }else{
                   setNFTList([]);
                }
            }else{
               setNFTList([]);
            }             
        setweaponLoader(0)
    }

  const cancelNftOrder = async (item) => {
        approveNFT(item, 1)
        // confirmAlert({
        //     title: 'Confirm to submit',
        //     message: 'Are you sure want to cancel this order?.',
        //     buttons: [
        //         {
        //             label: 'Yes',
        //             onClick: () =>
        //                 approveNFT(item, 1)
        //         },
        //         {
        //             label: 'No',
        //         }
        //     ]
        // });
    }

 const approveGroupNFT = async (itemDetails, cancelType = 0, loopindex) => {		
		if(cancelType==1)
		{
			closeModel();
		}
        if (window.ethereum) {
            let web3 = '';
            web3 = new Web3(window.ethereum);
            const accounts = await web3.eth.getAccounts();

            let walletAdd = accounts[0];
            if (!walletAdd) {
                toast.error('Please connect your metamask wallet.');
                return;
            } 
            else if (itemDetails.owner_address && walletAdd.toUpperCase() !== itemDetails.owner_address.toUpperCase()) {
                toast.error(`Please select (${itemDetails?.owner_address.substring(0, 8) + '...' + itemDetails?.owner_address.substr(itemDetails?.owner_address.length - 8)}) address to your metamask wallet.`);
                return;
            }
            let from_address = accounts[0];
            var getBalace = await web3.eth.getBalance(from_address) / (10 ** 18);
            var currentBal = parseFloat(getBalace).toFixed(6)
            if (currentBal == 0) {
                toast.error(`Insufficient fund!!`);
                return false;
            }

            let currentNetwork = await web3.currentProvider.chainId;
            web3.eth.defaultAccount = accounts[0];
            let chainId = config.chainId;
            if (currentNetwork !== chainId) {
                toast.error('Please select BNB smartchain!!');
                return false;
            }
             //  Approve transaction
                const contractForApprove = await new web3.eth.Contract(config.nftContractABI, itemDetails?.contractAddress);
                let isApproved = await contractForApprove.methods.isApprovedForAll(from_address, config.mainMarketplaceContract).call();

                if (isApproved == false) {
					try {
						let tx_builderForToken = await contractForApprove.methods.setApprovalForAll(config.mainMarketplaceContract.toString(), true);

						setSpinLoader(1);
						setDialogOpen(true);
								
						let encodedTxForToken = tx_builderForToken.encodeABI();
						let gasPriceForToken = await web3.eth.getGasPrice();

						let gasLimitForToken = await web3.eth.estimateGas({
							gasPrice: web3.utils.toHex(gasPriceForToken),
							to: itemDetails?.contractAddress,
							from: from_address,
							chainId: chainId,
							data: encodedTxForToken
						});

						await web3.eth.sendTransaction({
							gasPrice: web3.utils.toHex(gasPriceForToken),
							gas: web3.utils.toHex(gasLimitForToken),
							to: itemDetails?.contractAddress,
							from: from_address,
							chainId: chainId,
							data: encodedTxForToken
						});
						
                }
                catch (err) {
				   if (err.message.toString().split('insufficient funds')[1]) {
						toast.error('Transaction reverted : ' + err.message)
					} else {
						if (err.toString().split('execution reverted:')[1]) {
							toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])

						} else {
							if (err.toString().split('execution reverted:')[1]) {
								toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])
							} else {
								toast.error(err.message);
							}
						}
					}

					setSpinLoader(0);
					setisPutonsale(0);
					setDialogOpen(false);					
					return false;
				}
			}
			
            setSpinLoader(1);
            setisPutonsale(0);
            setDialogOpen(true);
            
            try {
                let mintFee = 0;
                let SalePrice;
                let start_date = 0;
                let expiry_date = 0;
                let tokenId = itemDetails.token_id;
                let royalty_percent = parseInt(parseInt(itemDetails?.royalty_percent) * 100);

                if (itemDetails.sell_type == 1) {
                    SalePrice = web3.utils.toWei(itemDetails.price.toString(), 'ether');       
                }

                else if (itemDetails.sell_type == 2) {
                    SalePrice = web3.utils.toWei(itemDetails.price.toString(), 'ether');       
                    start_date = Math.round(new Date(itemDetails.start_date).getTime() / 1000);
                    expiry_date = Math.round(new Date(itemDetails.expiry_date).getTime() / 1000);
                }
				
                let contractAddress = `${config.mainMarketplaceContract}`
                const contract = await new web3.eth.Contract(config.mainMarketplaceContractABI, contractAddress);

                let nftContractAddress = `${config.nftContract}`
                const nftContract = await new web3.eth.Contract(config.nftContractABI, nftContractAddress);
				
                if (cancelType == 1) {
                    setblockchainUpdationType(2)
                    await contract.methods.cancelOrder(itemDetails?.contractAddress.toString(), tokenId.toString()).call();
                    var tx_builder = await contract.methods.cancelOrder(itemDetails?.contractAddress.toString(), tokenId.toString());
                } else {
                    setblockchainUpdationType(1)
                    if (itemDetails.is_minted == 1) {
                   
                        await contract.methods.putonSale(tokenId.toString(), SalePrice.toString(), itemDetails?.contractAddress.toString(), itemDetails.sell_type.toString(), start_date.toString(), expiry_date.toString(), royalty_percent.toString() ).call();

                        var tx_builder = await contract.methods.putonSale(tokenId.toString(), SalePrice.toString(), itemDetails?.contractAddress.toString(), itemDetails.sell_type.toString(), start_date.toString(), expiry_date.toString(), royalty_percent.toString() );
                    } else {
                        await nftContract.methods._mint(tokenId.toString()).call();
                        var tx_builder = await nftContract.methods._mint(tokenId.toString());

                        let encoded_tx = tx_builder.encodeABI();
                        let gasPrice = await web3.eth.getGasPrice();
                        gasPrice = parseInt(gasPrice) + parseInt(10000000000);

                        let gasLimit = await web3.eth.estimateGas({
                            gasPrice: web3.utils.toHex(gasPrice),
                            to: itemDetails?.is_minted == 1 ? contractAddress : nftContractAddress,
                            from: from_address,
                            value: web3.utils.toHex(mintFee),
                            chainId: chainId,
                            data: encoded_tx
                        });

                        const txData = await web3.eth.sendTransaction({
                            gasPrice: web3.utils.toHex(gasPrice),
                            gas: web3.utils.toHex(gasLimit),
                            to: itemDetails?.is_minted == 1 ? contractAddress : nftContractAddress,
                            from: from_address,
                            value: web3.utils.toHex(mintFee),
                            chainId: chainId,
                            data: encoded_tx
                        });

                        if (txData.transactionHash) {

                            //  Approve transaction
                            const contractForApprove = await new web3.eth.Contract(config.nftContractABI, config.nftContract);
                            let isApproved = await contractForApprove.methods.isApprovedForAll(from_address, config.mainMarketplaceContract).call();							
                            if (isApproved == false) {
                                let tx_builderForToken = await contractForApprove.methods.setApprovalForAll(config.mainMarketplaceContract.toString(), true);							
                                setSpinLoader(1);
                                setDialogOpen(true);

                                let encodedTxForToken = tx_builderForToken.encodeABI();
                                let gasPriceForToken = await web3.eth.getGasPrice();

                                let gasLimitForToken = await web3.eth.estimateGas({
                                    gasPrice: web3.utils.toHex(gasPriceForToken),
                                    to: config.nftContract,
                                    from: from_address,
                                    chainId: chainId,
                                    data: encodedTxForToken
                                });

                                await web3.eth.sendTransaction({
                                    gasPrice: web3.utils.toHex(gasPriceForToken),
                                    gas: web3.utils.toHex(gasLimitForToken),
                                    to: config.nftContract,
                                    from: from_address,
                                    chainId: chainId,
                                    data: encodedTxForToken
                                });
                            }

                            await contract.methods.putonSale(tokenId.toString(), SalePrice.toString(), config.nftContract.toString(), itemDetails.sell_type.toString(), start_date.toString(), expiry_date.toString(), royalty_percent.toString() ).call();

                            var tx_builder = await contract.methods.putonSale(tokenId.toString(), SalePrice.toString(), config.nftContract.toString(), itemDetails.sell_type.toString(), start_date.toString(), expiry_date.toString(), royalty_percent.toString() );
                        }
                    }
                }

                let encoded_tx = tx_builder.encodeABI();
                let gasPrice = await web3.eth.getGasPrice();
                gasPrice = parseInt(gasPrice) + parseInt(10000000000);

                let gasLimit = await web3.eth.estimateGas({
                    gasPrice: web3.utils.toHex(gasPrice),
                    to: contractAddress,
                    from: from_address,
                    value: web3.utils.toHex(mintFee),
                    chainId: chainId,
                    data: encoded_tx
                });

                const txData = await web3.eth.sendTransaction({
                    gasPrice: web3.utils.toHex(gasPrice),
                    gas: web3.utils.toHex(gasLimit),
                    to: contractAddress,
                    from: from_address,
                    value: web3.utils.toHex(mintFee),
                    chainId: chainId,
                    data: encoded_tx
                });

                if (txData.transactionHash) {                
                    let dataArr = {                        
                        "wallet_address": from_address,
                        "user_id": loginData.id,
                        "item_id": itemDetails.id,
                        "token_hash": txData.transactionHash
                    }                   
                    let res;
                    if (cancelType) {
                        res = await cancelOrderAction(dataArr);
                    } else {
                        res = await putOnSaleAction(dataArr);
                    }
                    if (res.success === true) {                                           
                        return loopindex;                         
                    } else {
                        toast.error(res.msg);
                    }
                } else {
                    toast.error('Something went wrong please try again.');
                    setSpinLoader(0);
                    setisPutonsale(0);
                    setDialogOpen(false);
                    return false;
                }

            } catch (err) {
               if (err.message.toString().split('insufficient funds')[1]) {
                    toast.error('Transaction reverted : ' + err.message)
                } else {
                    if (err.toString().split('execution reverted:')[1]) {
                        toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])

                    } else {
                        if (err.toString().split('execution reverted:')[1]) {
                            toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])
                        } else {
                            toast.error(err.message);
                        }
                    }
                }                            	
                return false;
            }
        } else {
            toast.error('Please connect your metamask wallet.');
            setSpinLoader(0);
            setisPutonsale(0);
            setDialogOpen(false);
            return false;
        }
    }
	
 const approveNewGroupNFT = async (itemDetails, cancelType = 0, loopindex,grouplen) => {		
		if(cancelType==1)
		{
			closeModel();
		}
        if (window.ethereum) {
            let web3 = '';
            web3 = new Web3(window.ethereum);
            const accounts = await web3.eth.getAccounts();

            let walletAdd = accounts[0];
            if (!walletAdd) {
                toast.error('Please connect your metamask wallet.');
                return;
            } else if (itemDetails.owner_address && walletAdd.toUpperCase() !== itemDetails.owner_address.toUpperCase()) {
                toast.error(`Please select (${itemDetails?.owner_address.substring(0, 8) + '...' + itemDetails?.owner_address.substr(itemDetails?.owner_address.length - 8)}) address to your metamask wallet.`);
                return;
            }
           
            let from_address = accounts[0];
            var getBalace = await web3.eth.getBalance(from_address) / (10 ** 18);
            var currentBal = parseFloat(getBalace).toFixed(6)
            if (currentBal == 0) {
                toast.error(`Insufficient fund!!`);
                return false;
            }

            let currentNetwork = await web3.currentProvider.chainId;
            web3.eth.defaultAccount = accounts[0];
            let chainId = config.chainId;
            if (currentNetwork !== chainId) {
                toast.error('Please select BNB smartchain!!');
                return false;
            }
            
             //  Approve transaction
                const contractForApprove = await new web3.eth.Contract(config.nftContractABI, itemDetails?.contractAddress);
                let isApproved = await contractForApprove.methods.isApprovedForAll(from_address, config.mainMarketplaceContract).call();

                if (isApproved == false) {
					try {
						let tx_builderForToken = await contractForApprove.methods.setApprovalForAll(config.mainMarketplaceContract.toString(), true);

						setSpinLoader(1);
						setDialogOpen(true);
								
						let encodedTxForToken = tx_builderForToken.encodeABI();
						let gasPriceForToken = await web3.eth.getGasPrice();

						let gasLimitForToken = await web3.eth.estimateGas({
							gasPrice: web3.utils.toHex(gasPriceForToken),
							to: itemDetails?.contractAddress,
							from: from_address,
							chainId: chainId,
							data: encodedTxForToken
						});

						await web3.eth.sendTransaction({
							gasPrice: web3.utils.toHex(gasPriceForToken),
							gas: web3.utils.toHex(gasLimitForToken),
							to: itemDetails?.contractAddress,
							from: from_address,
							chainId: chainId,
							data: encodedTxForToken
						});
						
                }
                catch (err) {
				   if (err.message.toString().split('insufficient funds')[1]) {
						toast.error('Transaction reverted : ' + err.message)
					} else {
						if (err.toString().split('execution reverted:')[1]) {
							toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])

						} else {
							if (err.toString().split('execution reverted:')[1]) {
								toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])
							} else {
								toast.error(err.message);
							}
						}
					}

					setSpinLoader(0);
					setisPutonsale(0);
					setDialogOpen(false);					
					return false;
				}
			}
			
            setSpinLoader(1);
            setisPutonsale(0);
            setDialogOpen(true);
            
            try {
                let mintFee = 0;
                let SalePrice;
                let start_date = 0;
                let expiry_date = 0;
                let tokenId = itemDetails.token_id;
                let royalty_percent = parseInt(parseInt(itemDetails?.royalty_percent) * 100);

                if (itemDetails.sell_type == 1) {
                    SalePrice = web3.utils.toWei(itemDetails.price.toString(), 'ether');       
                }

                else if (itemDetails.sell_type == 2) {
                    SalePrice = web3.utils.toWei(itemDetails.price.toString(), 'ether');       
                    start_date = Math.round(new Date(itemDetails.start_date).getTime() / 1000);
                    expiry_date = Math.round(new Date(itemDetails.expiry_date).getTime() / 1000);
                }
				
                let contractAddress = `${config.mainMarketplaceContract}`
                const contract = await new web3.eth.Contract(config.mainMarketplaceContractABI, contractAddress);

                let nftContractAddress = `${config.nftContract}`
                const nftContract = await new web3.eth.Contract(config.nftContractABI, nftContractAddress);
			
                if (cancelType == 1) {
                    setblockchainUpdationType(2)
                    await contract.methods.cancelOrder(itemDetails?.contractAddress.toString(), tokenId.toString()).call();
                    var tx_builder = await contract.methods.cancelOrder(itemDetails?.contractAddress.toString(), tokenId.toString());
                } else {
                    setblockchainUpdationType(1)
                    if (itemDetails.is_minted == 1) {                 
                 
                        await contract.methods.putonSale(tokenId.toString(), SalePrice.toString(), itemDetails?.contractAddress.toString(), itemDetails.sell_type.toString(), start_date.toString(), expiry_date.toString(), royalty_percent.toString() ).call();

                        var tx_builder = await contract.methods.putonSale(tokenId.toString(), SalePrice.toString(), itemDetails?.contractAddress.toString(), itemDetails.sell_type.toString(), start_date.toString(), expiry_date.toString(), royalty_percent.toString() );
                    } else {
                        await nftContract.methods._mint(tokenId.toString()).call();
                        var tx_builder = await nftContract.methods._mint(tokenId.toString());

                        let encoded_tx = tx_builder.encodeABI();
                        let gasPrice = await web3.eth.getGasPrice();
                        gasPrice = parseInt(gasPrice) + parseInt(10000000000);

                        let gasLimit = await web3.eth.estimateGas({
                            gasPrice: web3.utils.toHex(gasPrice),
                            to: itemDetails?.is_minted == 1 ? contractAddress : nftContractAddress,
                            from: from_address,
                            value: web3.utils.toHex(mintFee),
                            chainId: chainId,
                            data: encoded_tx
                        });

                        const txData = await web3.eth.sendTransaction({
                            gasPrice: web3.utils.toHex(gasPrice),
                            gas: web3.utils.toHex(gasLimit),
                            to: itemDetails?.is_minted == 1 ? contractAddress : nftContractAddress,
                            from: from_address,
                            value: web3.utils.toHex(mintFee),
                            chainId: chainId,
                            data: encoded_tx
                        });

                        if (txData.transactionHash) {

                            //  Approve transaction
                            const contractForApprove = await new web3.eth.Contract(config.nftContractABI, config.nftContract);
                            let isApproved = await contractForApprove.methods.isApprovedForAll(from_address, config.mainMarketplaceContract).call();							
                            if (isApproved == false) {
                                let tx_builderForToken = await contractForApprove.methods.setApprovalForAll(config.mainMarketplaceContract.toString(), true);							
                                setSpinLoader(1);
                                setDialogOpen(true);

                                let encodedTxForToken = tx_builderForToken.encodeABI();
                                let gasPriceForToken = await web3.eth.getGasPrice();

                                let gasLimitForToken = await web3.eth.estimateGas({
                                    gasPrice: web3.utils.toHex(gasPriceForToken),
                                    to: config.nftContract,
                                    from: from_address,
                                    chainId: chainId,
                                    data: encodedTxForToken
                                });

                                await web3.eth.sendTransaction({
                                    gasPrice: web3.utils.toHex(gasPriceForToken),
                                    gas: web3.utils.toHex(gasLimitForToken),
                                    to: config.nftContract,
                                    from: from_address,
                                    chainId: chainId,
                                    data: encodedTxForToken
                                });
                            }

                            await contract.methods.putonSale(tokenId.toString(), SalePrice.toString(), config.nftContract.toString(), itemDetails.sell_type.toString(), start_date.toString(), expiry_date.toString(), royalty_percent.toString() ).call();

                            var tx_builder = await contract.methods.putonSale(tokenId.toString(), SalePrice.toString(), config.nftContract.toString(), itemDetails.sell_type.toString(), start_date.toString(), expiry_date.toString(), royalty_percent.toString() );
                        }
                    }
                }

                let encoded_tx = tx_builder.encodeABI();
                let gasPrice = await web3.eth.getGasPrice();
                gasPrice = parseInt(gasPrice) + parseInt(10000000000);

                let gasLimit = await web3.eth.estimateGas({
                    gasPrice: web3.utils.toHex(gasPrice),
                    to: contractAddress,
                    from: from_address,
                    value: web3.utils.toHex(mintFee),
                    chainId: chainId,
                    data: encoded_tx
                });

                const txData = await web3.eth.sendTransaction({
                    gasPrice: web3.utils.toHex(gasPrice),
                    gas: web3.utils.toHex(gasLimit),
                    to: contractAddress,
                    from: from_address,
                    value: web3.utils.toHex(mintFee),
                    chainId: chainId,
                    data: encoded_tx
                });

                if (txData.transactionHash) {                
                    let dataArr = {                        
                        "wallet_address": from_address,
                        "user_id": loginData.id,
                        "item_id": itemDetails.id,
                        "group_id": itemDetails.group_id,
                        "token_hash": txData.transactionHash
                    }                   
                    let res;
                    if (cancelType) {
                        res = await cancelOrderAction(dataArr);
                    } else {
                        res = await putgroupItemOnSaleAction(dataArr);
                    }
                    if (res.success === true) {  
						if(loopindex==grouplen) 
						{
							setSpinLoader(0);
							setisPutonsale(0);
							setDialogOpen(false);  
							toast.success(res.msg); 
							getLandListAPI(walletAddress);							
							setTimeout(() => {
										window.location.href = `${config.baseUrl}portfolio-land`;
									}, 2000);
						}                                        
                                                
                    } else {
                        toast.error(res.msg);
                        setTimeout(() => {
										window.location.href = `${config.baseUrl}portfolio-land`;
									}, 2000);
                    }
                } else {
                    toast.error('Something went wrong please try again.');
                    setSpinLoader(0);
                    setisPutonsale(0);
                    setDialogOpen(false);
                    return false;
                }

            } catch (err) {
               if (err.message.toString().split('insufficient funds')[1]) {
                    toast.error('Transaction reverted : ' + err.message)
                } else {
                    if (err.toString().split('execution reverted:')[1]) {
                        toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])

                    } else {
                        if (err.toString().split('execution reverted:')[1]) {
                            toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])
                        } else {
                            toast.error(err.message);
                            
                        }
                    }
                } 
                setSpinLoader(0);
				setisPutonsale(0);
				setDialogOpen(false); 
                setTimeout(() => {
							window.location.href = `${config.baseUrl}portfolio-land`;
						}, 2000);                          	
                return false;
            }
        } else {
            toast.error('Please connect your metamask wallet.');
            setSpinLoader(0);
            setisPutonsale(0);
            setDialogOpen(false);
            return false;
        }
    }
	
  const cancelGroupNftOrder = async (item) => {
	  
	  let res = await getLandgroupDetailsAction({'id' : item.id});
        if (res.success) {
			let groupNfts = res.groupNfts;
           if(groupNfts.length > 0)
           {
			    const promises = groupNfts.map(async (groupNft,index) => {
					// Perform some operation or API call for each item
					const response = await approveGroupNFT(groupNft, 1,index);
					return response;
				  });			
			  const result = await Promise.all(promises);
			
			  if(result.length === groupNfts.length) 
			  {
				   let res = await updateLandgroupAction({'id' : item.id, 'is_on_sale': 0});
				   if (res.success) {
						setSpinLoader(0);
						setisPutonsale(0);
						setDialogOpen(false);  
						toast.success(res.msg); 
						getLandListAPI(walletAddress);
						
					}					
			}  
			   
		   }		  
        }
        
        
       
    }
 	
  const putGroupNftOnsale = async (item) => {
	  
	  let res = await getLandgroupAllNFTsDetailsAction({'id' : item.id});
        if (res.success) {
			let groupNfts = res.groupNfts;
			
           if(groupNfts.length > 0)
           {
			    const promises = groupNfts.map(async (groupNft,index) => {
					// Perform some operation or API call for each item
					
					const response = await approveGroupNFT(groupNft, 0,index);
				
					return response;
				  });			
			  const result = await Promise.all(promises);
			  if(result.length === groupNfts.length) 
			  {
				   let res = await updateLandgroupAction({'id' : item.id,'is_on_sale': 1});
				   if (res.success) {
						setSpinLoader(0);
						setisPutonsale(0);
						setDialogOpen(false);  
						toast.success(res.msg); 
						getLandListAPI(walletAddress);						
					}
			}  
			   
		   }		  
        }
               
       
    }

  const CreateGroupModelAPI = async () => {
		groupDetails.contractAddress 	= selectedContract;
		groupDetails.owner_address 		= walletAddress;
		groupDetails.created_by 		= loginData.id;
        setCreatGroup(1);
        setisPutonsale(1);
        
    }
    
  function validateGroup() {	
        let grouptitleError 		= "";
        let groupdescriptionError 	= "";       
        let grouppriceError 		= ""; 
        let price					= 0; 
       selectedItems.forEach((item) => {
		  price = price + parseInt(item.price);
		});
        groupDetails.price = price;
             
        if (groupDetails.title === '') {
            grouptitleError = "Title field is required."
        }
        if (groupDetails.description === '') {
            groupdescriptionError = "Description field is required."
        }        
        if (groupDetails.price == 0) {
            grouppriceError = "Price field is required."
        }       
        if (grouptitleError || groupdescriptionError || grouppriceError) {
            setvalidatioError({
                grouptitleError, groupdescriptionError, grouppriceError})
            return false
        } else {
            return true
        }
    }
	
  const CreateGroupNFT = async (e) => {
        e.preventDefault()
        const isValid = validateGroup();
        if (!isValid) {

        }
        else if(selectedItems.length ==0)
        {
			 toast.error('Please select items');
		}
        else {
			setSpinLoader(1);	 
			 let res = await CreateGroupAction(groupDetails);
			 if (res.success) {
				  let grouplength    	= selectedItems.length-1;					  
				  for(let i=0;i<selectedItems.length;i++)
					{
										
						selectedItems[i].group_id = res.data
						await approveNewGroupNFT(selectedItems[i],0,i,grouplength)										
					}
			
			}
		}
        
    }

    
  
    return (
        <div className='auctions'>
            <Toaster />

            <Modal
                isOpen={isDialogOpen}
                onAfterOpen={afterOpenModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="text-center pl-3 pr-3">
                    < br />
                    {blockchainUpdationType == 1 ?
                        <h4 style={{ color: '#d71e5b', fontSize: '16px' }}>
                            Put on sale in progress, once the process is completed, the NFT will be displayed on the marketplace page.
                        </h4>
                        :
                        blockchainUpdationType == 2 ?
                            <h4 style={{ color: '#d71e5b', fontSize: '16px' }}>
                                Canceling your listing will unpublish this sale from Sinverse and requires a transaction.
                            </h4>
                            :
                            <h4 style={{ color: '#d71e5b', fontSize: '16px' }}>
                                Bid accepting in progress, Please wait for a while.
                            </h4>
                    }

                    <p style={{ color: '#091f3f' }}>
                        Please do not refresh the page or close the tab.
                    </p>
                    <div>
                        <img src="images/loader.gif" height={50} width={50} />
                    </div>
                </div>
            </Modal>

            <Header data={{'cartcount':cartcount}} />
            <div className="market-place-banner">
			  <h1>Portfolio</h1>
			</div>
			<div className="market-place">
			<div className="container">
			<div className="row">
			  <div className="col-12 col-md-3 col-lg-2 px-0 ">
				<div className="left-sidebar">
				  <ul>
					<li><Link to={`${config.baseUrl}portfolio-weapon`} className="">Weapon & Armor Collections</Link></li>
					<li className="active"><a href="javascript:;" onClick={() => { showland() }}>Land</a></li>
					<li><Link to={`${config.baseUrl}portfolio-license`} className="">License</Link></li>
					<li><Link to={`${config.baseUrl}portfolio-other`} className="">Other NFTs</Link></li>
					<li><Link to={`${config.baseUrl}mystery-loot-crates`} className="">Mystery Loot Crates</Link></li>								
				  </ul>
				</div>
			  </div>
			  <div className="col-12 col-md-9 col-lg-10 pl-4">
				<div className="row">
				{island === 1 ?  
					<>
					 <div className="col-12 d-flex pro-filter-outer">					
					<div className="pro-search">
					  <input type="text" id="search" className="form-control w210px" placeholder="Search (Title Or Token ID)" onChange={SetLNameFilter} name="s" title="Search for" value={SearchLFilter} onKeyDown={() => handleLKeyPress(this)} />
					</div>
				  </div>
					 <div className="col-6">
					   { landLoader==0 ? FilterNFTList.length != 0 ?
							<h2 className="title">Single Land plots for Sale</h2>
							:''
							: ''
						}
					  </div>					   
					  <div className="col-6 text-right">
						{selectedItems.length >=2 ? <button onClick={() => { CreateGroupModelAPI() }} className='custom-btn' data-toggle="modal" data-target="#putOnSale">Create Group {selectedItems.length} NFTs</button> :'' }
					  </div>					 
					 <div className="col-12">
					  <p className="clr-wt">All the NFTs purchased from the marketplace or which are put to sale from Collected NFT section are shown here.</p>
					  </div>
					  { landLoader==0 ? FilterNFTList.length == 0 && LandGroupList.length === 0 ?
						<>
							<h4 className="not-found">No land collection found!!!</h4>
						</>
						:
						FilterNFTList.map((litem) => (
					  
						 <div className="col-lg-4 col-md-6">
							<div className="tab-section-main">	
							<div className="tab section">
								<div className="profile-images">
								{
								  litem.is_on_sale === 0 ? (
									selectedContract !== null && selectedContract.toLowerCase() !== litem.contractAddress.toLowerCase() ? (
									  <>
										<label className="check-container" onClick={handleOtherContractClick}>
										  <input disabled type="checkbox" />
										  <span className="checkmark"></span>
										</label>
									  </>
									) : (
									  <>
										<label className="check-container">
										  <input type="checkbox" onChange={(event) => { handleCheckboxChange(event,litem,litem.id,litem.contractAddress) }} />
										  <span className="checkmark"></span>
										</label>
									  </>
									)
								  ) : null
								}																		
								</div>
								<div className="Andrew-text">
								<p>Contract Address: {litem.contractAddress.toString().substring(0, 6) + '...' + litem.contractAddress.toString().substring(litem.contractAddress.length - 6)}</p>	
								</div>
							</div>							
								<div className="images-sec">
									<Link to={`${config.baseUrl}nft-details/` + litem.id}><img src="images/lant-plot.png" /></Link>
								
								</div>
								<div className="section-map">
									<h3><Link to={`${config.baseUrl}nft-details/` + litem.id}>
									{
									  windowWidth < 1299 ?	
									  <>					
										<span className="fnt-16" >{ litem.name.toString().substring(0, 15) } { litem.name.length > 15 ? '...' : ''}</span>
										</>
										:
										<>					
										<span className="fnt-16" >{ litem.name.toString().substring(0, 23) }
										{ litem.name.length > 23 ? '...' : ''}
										</span>
										</>			
										
									}
									</Link> <span className="pull-right"><a href="javascript:;" onClick={() => { ShowPlotDetails(litem.cell_number) }}><i class="fa-regular fa-eye" aria-hidden="true"></i></a></span>
									<br />
									<span className="">{litem.city_name}</span>
									</h3>
								</div>
								<div className="price-section">
								<div className="price">
										<p>Price</p>
										<h3>{parseInt(litem.price).toLocaleString('en-US')} SIN</h3>
									</div>
									<div className="price">
										<p>Token ID</p>
										<h3>#{litem.token_id}</h3>
									</div>									
									
								</div>
								<div className="View-History flx">
									{litem.is_on_sale == 1 ?
										(
										<button onClick={() => { cancelNftOrder(litem, 1) }} className='tab-btn' data-toggle="modal" data-target="#putOnSale">
										{ litem.not_owner &&  litem.not_owner==1 ? 'Cancel Listing': 'Cancel Listing'}
										</button>
										)
										:
										litem.price < 1 ?
										<>
											<button onClick={() => { putOnSaleModelNewAPI(litem) }} className='tab-btn' data-toggle="modal" data-target="#putOnSale">Put On Sale</button>

											&nbsp;
											<Link to={`${config.baseUrl}edit-nft/` + litem.id} >
												<button className='tab-btn'>Edit</button>
											</Link>
										</>
										:
										<>
											<button onClick={() => { putOnSaleModelAPI(litem) }} className='tab-btn' data-toggle="modal" data-target="#putOnSale">Put On Sale</button>

											&nbsp;
											<Link to={`${config.baseUrl}edit-nft/` + litem.id} >
												<button className='tab-btn'>Edit</button>
											</Link>
										</>
										  }
							
							</div>
							</div>
						</div>
									
						))
						:
							(
							 <div className="loaderDiv">
							  <img className='loaderImg' src="images/loader.gif" height={50} width={50} />
							  </div>
							  )
						}						
						{
							  LandGroupList.length === 0  ? (
								''
							  ) : (
								<>
								  <div className="col-12">
									<h2 className="title">Group Land plots for Sale</h2>
								  </div>
								  {LandGroupList.map((lgroupitem) => (
									<div className="col-lg-4 col-md-6" key={lgroupitem.id}>
									  <div className="tab-section-main">
										<div className="images-sec">
										  <Link to={`${config.baseUrl}land-group-details/` + lgroupitem.id}>
											<img src="images/lant-plot.png" alt="Land Group" />
										  </Link>
										</div>
										<div className="section-map">
										  <h3>
											<Link to={`${config.baseUrl}nft-details/` + lgroupitem.id}>
											{
											  windowWidth < 1299 ?						
												lgroupitem.title.toString().substring(0, 15) + '...'
												:
												lgroupitem.title.toString().substring(0, 23) +
												(lgroupitem.title.length > 23 ? '...' : '')
											}											
											 </Link> <span className="pull-right"><a href="javascript:;" onClick={() => { ShowGroupPlotDetails(lgroupitem.landgroupcells) }}><i class="fa-regular fa-eye" aria-hidden="true"></i></a></span>
										  </h3>
										</div>
										<div className="price-section">
										  <div className="price">
											<p>Price</p>
											<h3>{parseInt(lgroupitem.price).toLocaleString('en-US')} SIN</h3>
										  </div>
										</div>
										<div className="View-History flx">
										  {lgroupitem.is_on_sale === 1 ? (
											<button
											  onClick={() => {
												cancelGroupNftOrder(lgroupitem, 1);
											  }}
											  className="tab-btn"
											  data-toggle="modal"
											  data-target="#putOnSale"
											>
											  Cancel Listing
											</button>
										  ) : (
											<>
											  <button
												onClick={() => {
												  putGroupNftOnsale(lgroupitem);
												}}
												className="tab-btn"
												data-toggle="modal"
												data-target="#putOnSale"
											  >
												Put On Sale
											  </button>
											</>
										  )}
										</div>
									  </div>
									</div>
								  ))}
								</>
							  )
							}
							</>
							:''
					}
						
						
					</div>
					</div>
					</div>
					</div>
					</div>
            {/* Put on sale model */}
            {isPutonsale === 1 ?
				<>
            <div className={isPutonsale === 0 ? "modal fade" : "modal fade show"} id="putOnSale" style={{ display: isPutonsale === 0 ? 'none' : 'block' }} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false" data-id={isPutonsale}>
                <div className="modal-dialog" role="document">
					{isCreatGroup === 0 ?
						<>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"> Put On Sale </h5>
                            <a type="button" className="close" data-dismiss="modal" style={{
                                fontSize: '26px'
                            }} aria-label="Close" onClick={closeModel} >
                                <span aria-hidden="true">&times;</span>
                            </a>
                        </div>

                        <div className="modal-body">
                            <div className="de_tab tab_methods">
                                <div className="de_tab_content">
                                    <span style={{ color: 'white' }}>List price and listing schedule can not be edited once the item is listed. You will need to cancel your listing and relist the item with the updated price. </span><br /><br />
                                    {itemDetails?.sell_type === 1 ?
                                        <>
                                            <h5>Price: {parseInt(itemDetails?.price).toLocaleString('en-US')} SIN</h5>
                                        </>
                                        :
                                        itemDetails?.sell_type === 2 ?
                                            <>
                                                <div className="row" style={{ lineHeight: '22px' }}>
                                                    <div className="col-md-6">
                                                        <h5>NFT Type</h5>
                                                        Auction
                                                    </div>

                                                    <div className="col-md-6">
                                                        <h5>Minimum bid</h5>
                                                        {itemDetails?.price} SIN
                                                    </div>
                                                </div>
                                                <br />

                                                <div className="spacer-10" />
                                                <div className="row" style={{ lineHeight: '22px' }}>
                                                    <div className="col-md-6">
                                                        <h5>Starting date</h5>
                                                        {itemDetails?.start_date ? itemDetails?.start_date : ''}
                                                    </div>
                                                    <div className="col-md-6">
                                                        <h5>Expiration date</h5>
                                                        {itemDetails?.expiry_date ? itemDetails?.expiry_date : ''}

                                                    </div>
                                                    <div className="spacer-single" />
                                                </div>
                                            </>
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                            <div className="spacer-10 mt-10" />
                            {spinLoader == '0' ?
									<>
                                <input disabled={spinLoader ? true : false} type="submit"  onClick={() => { approveNFT(itemDetails) }} value={spinLoader == 1 ? 'Processing...' : `Approve`} id="submit" className="tab-btn" defaultValue="Create Item" />
                                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                 <input type="button"  onClick={() => { EditNFTDetails(itemDetails.id) }} value="Edit" id="Edit" className="tab-btn crsr" defaultValue="Edit Item" />
                                </>
                                :
                                <button disabled className="tab-btn" id="deposit-page" >Processing &nbsp; <i className="fa fa-spinner fa-spin validat"></i></button>
                            }
                            <div className="spacer-single" />
                        </div>
                    </div>
                     </>
                    :
                    isCreatGroup === 2 ?
                    <>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"> Put On Sale </h5>
                            <a type="button" className="close" data-dismiss="modal" style={{
                                fontSize: '26px'
                            }} aria-label="Close" onClick={closeModel} >
                                <span aria-hidden="true">&times;</span>
                            </a>
                        </div>

                        <div className="modal-body">
                        <form onSubmit={UpdateNFTPricing}>
                            <div className="de_tab tab_methods">
                                <div className="de_tab_content">                                 
                                    <span style={{ color: 'white' }}>List price and listing schedule can not be edited once the item is listed. You will need to cancel your listing and relist the item with the updated price. </span><br /><br />
                                    <div className="row-form style-3 mt-4">
										<div className="inner-row-form">
											<h4 className="title-create-item">Price(SIN)</h4>
											<span className="validationErr">{validatioError.priceError}</span>
											<input name='price' id='priceError' onChange={inputHandler} type="text" placeholder="Enter Price (SIN)" onKeyPress={(event) => { if (!/^\d*[]?\d{0,1}$/.test(event.key)) { event.preventDefault(); } }} />
										</div>
									</div>                                   
                                </div>
                            </div>
                            <div className="spacer-10 mt-10 mt-4" />
                            {spinLoader == '0' ?
									<>
                                <input disabled={spinLoader ? true : false} type="submit"  value={spinLoader == 1 ? 'Processing...' : `Approve`} id="submit" className="tab-btn" defaultValue="Create Item" />
                                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                 <input type="button"  onClick={() => { EditNFTDetails(itemDetails.id) }} value="Edit" id="Edit" className="tab-btn crsr" defaultValue="Edit Item" />
                                </>
                                :
                                <button disabled className="tab-btn" id="deposit-page" >Processing &nbsp; <i className="fa fa-spinner fa-spin validat"></i></button>
                            }
                            <div className="spacer-single" />
                            </form>
                        </div>
                    </div>
                   
                     </>
                    :
                     <>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"> Put On Sale </h5>
                            <a type="button" className="close" data-dismiss="modal" style={{
                                fontSize: '26px'
                            }} aria-label="Close" onClick={closeModel} >
                                <span aria-hidden="true">&times;</span>
                            </a>
                        </div>  
                        <div className="modal-body">
                            <div className="de_tab tab_methods">
                                <div className="de_tab_content">
                                    <form onSubmit={CreateGroupNFT}>
                                    <span className="validationErr">{validatioError.grouppriceError}</span>
                                    <div className="row-form style-3">        
                                        <h4 className="title-create-item">Title</h4>
                                        <span className="validationErr">{validatioError.grouptitleError}</span>
                                        <input type="text" placeholder="Enter Title" id='grouptitleError' value={groupDetails.title} name='title' onChange={groupinputHandler} />
                                        </div>
									<div className="row-form style-3 mt-4">        
                                        <h4 className="title-create-item">Description</h4>
                                        <span className="validationErr">{validatioError.groupdescriptionError}</span>
                                        <textarea id='groupdescriptionError' name='description' onChange={groupinputHandler} placeholder="e.g. “This is very limited item”" value={groupDetails.description}></textarea>
                                     </div> 
									<div className="row-form style-3 mt-4">       
									 <div className="inner-row-form style-2">
										<div className="seclect-box">
											{spinLoader == '0' ?
												<input disabled={spinLoader ? true : false} type="submit" value={spinLoader == 1 ? 'Processing...' : `Create Group`} id="submit" className="custom-btn" defaultValue="Create Group" />
												:
												<button disabled className="custom-btn" id="deposit-page" >Processing &nbsp; <i className="fa fa-spinner fa-spin validat"></i></button>
											}
											<div className="spacer-single" />
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
                    </div>
                    </>
				}                
                </div>
            </div>
			</>
			: ''
		}
            
            <div id="myModal" className={modalopen == '0' ? "modal fade cart-modal mc-popup" : "mc-popup modal fade cart-modal show"} role="dialog" style={{ background: '0% 0% / cover rgba(6, 6, 6, 0.32)', display: modalopen == '0' ? 'none' : 'block' }}
                data-backdrop="false">
                <div className="modal-dialog modal-dialog-centered gridmodal">
                    <div className="modal-content">
						<button type="button" onClick={closebutton} className="close btnClose" data-dismiss="modal">&times;</button>                                      
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="row">                                        
                                        <div class="graph text-center">
											<canvas id="myCanvas"></canvas>
										</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
            <Footer />
        </div>
    );
}

export default Portfolio;
