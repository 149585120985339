import config from '../config'
import AuthLogin from "./AuthLogin";
import Home from "./Home";
import Marketplace from "./Marketplace";
import Land from "./Land";
import Safenfts from "./Safenfts";
import Licenses from "./Licenses";
import Licensecity from "./LicenseCity";
import CoffeeShopList from "./CoffeeShopList";
import WeedFarmList from "./WeedFarmList";
import BuyCoffeeLicense from "./BuyCoffeeLicense";
import BuyWeedFarmLicense from "./BuyWeedFarmLicense";
import Login from "./Login";
import SignUp from "./SignUp";
import VerifyAccount from "./Login";
import ForgetPassword from "./ForgetPassword";
import Resetpassword from "./Resetpassword";
import Profile from "./Profile";
//~ import CreateNFT from "./CreateNFT";
import Portfolio from "./PortfolioWeapon";
import PortfolioLand from "./PortfolioLand";
import PortfolioLicense from "./PortfolioLicense";
import PortfolioOther from "./PortfolioOther";
import CollectedNFTs from "./collectedNFTs";
import WalletNFTs from "./walletNFTs";
import EditNft from "./EditNft";
import NFTDetails from "./NFTDetails";
import Landgroup from "./Landgroup";
import LicenseDetails from "./LicenseDetails";
import NFTDetail from "./NFTDetail";
import TransactionList from "./TransactionList";
import BidsHistory from "./BidsHistory";
import TermsCondition from "./TermsCondition";
import PrivacyPolicy from "./PrivacyPolicy";
import ContactUs from "./ContactUs";
import FAQs from "./FAQ";
import About from "./About";
import Community from "./Community";
import BuyCoin from "./BuyCoin";
import BuyCrate from "./BuyCrate";
import CrateDetails from "./CrateDetails";
import BuyGoldCoin from "./BuyGoldCoin";
import NFTmetadata from "./Nft.js";
import UserPortfolio from "./UserPortfolio";
import Cart from "./Cart";
import Page404 from "./Page404";



const routes = [
  { path: `${config.baseUrl}/auth-login`, component: <AuthLogin />},
  { path: `${config.baseUrl}`, component: <Home />},
  { path: `${config.baseUrl}marketplace`, component: <Marketplace />},
  { path: `${config.baseUrl}land-marketplace`, component: <Land />},
  { path: `${config.baseUrl}othernfts`, component: <Safenfts />},
  { path: `${config.baseUrl}licenses-cities`, component: <Licenses />},
  { path: `${config.baseUrl}licenses-city/:id` , component: <Licensecity />},
  { path: `${config.baseUrl}coffee-shop-list/:id` , component: <CoffeeShopList />},
  { path: `${config.baseUrl}weed-farm-list/:id` , component: <WeedFarmList />},  
  { path: `${config.baseUrl}coffee-shop-license/:id` , component: <BuyCoffeeLicense />},
  { path: `${config.baseUrl}weed-farm-license/:id` , component: <BuyWeedFarmLicense />},  
  { path: `${config.baseUrl}login` , component: <Login />},
  { path: `${config.baseUrl}sign-up`, component: <SignUp />},
  { path: `${config.baseUrl}verifyAccount/:token` , component: <VerifyAccount />},
  { path: `${config.baseUrl}forget-password` , component: <ForgetPassword />},
  { path: `${config.baseUrl}resetpassword/:token` , component: <Resetpassword />},
  { path: `${config.baseUrl}profile` , component: <Profile />},
  //~ { path: `${config.baseUrl}create-nft` , component: <CreateNFT />},   
  { path: `${config.baseUrl}portfolio-weapon` , component: <Portfolio />}, 
  { path: `${config.baseUrl}portfolio-land` , component: <PortfolioLand />}, 
  { path: `${config.baseUrl}portfolio-license` , component: <PortfolioLicense />}, 
  { path: `${config.baseUrl}portfolio-other` , component: <PortfolioOther />}, 
  { path: `${config.baseUrl}collectedNFTs` , component: <CollectedNFTs />},
  //~ { path: `${config.baseUrl}walletNFTs` , component: <WalletNFTs />},   
  { path: `${config.baseUrl}edit-nft/:id` , component: <EditNft />},
  { path: `${config.baseUrl}nft-details/:id` , component: <NFTDetails />},
  { path: `${config.baseUrl}land-group-details/:id` , component: <Landgroup />},
  { path: `${config.baseUrl}license-details/:id` , component: <LicenseDetails />},
  { path: `${config.baseUrl}collected-detail/:contract/:id` , component: <NFTDetail />},
  { path: `${config.baseUrl}cart/` , component: <Cart />}, 
  { path: `${config.baseUrl}transactions-list` , component: <TransactionList />},
  { path: `${config.baseUrl}bids-history` , component: <BidsHistory />},
  { path: `${config.baseUrl}terms-condition` , component: <TermsCondition />},
  { path: `${config.baseUrl}privacy-policy` , component: <PrivacyPolicy />},    
  { path: `${config.baseUrl}contact-us` , component: <ContactUs />},
  //~ { path: `${config.baseUrl}gold-coins` , component: <BuyCoin />},  
  //~ { path: `${config.baseUrl}buy-gold-coins/:token` , component: <BuyGoldCoin />},  
  { path: `${config.baseUrl}mystery-loot-crates` , component: <BuyCrate />},
  { path: `${config.baseUrl}mystery-loot-crate-details/:id` , component: <CrateDetails />},
 
  { path: `${config.baseUrl}faqs` , component: <FAQs />}, 
  { path: `${config.baseUrl}about` , component: <About />}, 
  { path: `${config.baseUrl}community` , component: <Community />}, 
  { path: `${config.baseUrl}nft-metadata/:id` , component: <NFTmetadata />}, 
  { path: `${config.baseUrl}user-portfolio/:id` , component: <UserPortfolio />}, 
  { path: `${config.baseUrl}*` , component: <Page404 />}, 
]

export default routes;
