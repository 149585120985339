import React, { useState, Fragment, useEffect } from 'react';
import { Link, useSearchParams  } from 'react-router-dom'
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { Accordion } from 'react-bootstrap-accordion'
import { getMarketplaceNFTsAction, getCategoryAction, nftLikeDislikeAction,getcartcountAction, getSettingsAction } from '../Action/user.action';
import config from '../config';
import Cookies from 'js-cookie';
import $ from 'jquery';
import toast, { Toaster } from 'react-hot-toast';
import DamageRating from '../components/layouts/DamageRating';
import AccuracyRating from '../components/layouts/AccuracyRating';
import RateRating from '../components/layouts/RateRating';
const loginData = (!Cookies.get('loginSuccessSinverseUserpanel')) ? [] : JSON.parse(Cookies.get('loginSuccessSinverseUserpanel'));

const Marketplace = () => {
	const [searchParams] = useSearchParams();
	 const [settings, setSettings] = useState([]);
	const [cartcount, setcartcount] = useState(0);
	const [SearchFilter, setSearchFilter] = useState(searchParams.get('s'));
    const [visible, setVisible] = useState(6);   
    const [NFTList, setNFTList] = useState([]);
    const [marketplaceNFTList, setmarketplaceNFTList] = useState([]);
    const [Category, setCategory] = useState([]);
    const [CategoryIdsFilters, setCategoryIdsFilters] = useState([]);
    const [RarityIdsFilter, setRarityFilter] = useState([]);    
    const [PriceFilterData, setPriceFilterData] = useState([]);
    const [NFTTypeData, setNFTTypeData] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
   
	useEffect(async () => {
        getNFTListAPI();
        getCategoryAPI();
        getSettingsAPI();
         if (window.ethereum) {
            window.ethereum.on('accountsChanged', function (accounts) {
                 if (accounts) {
                    getcartcountAPI(accounts[0]);
                    
                }
            })
        }
		
       if (window.ethereum) {
                const { ethereum } = window;
                getcartcountAPI(ethereum.selectedAddress);
            }
    }, []);

    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 6);
    }
    
    const getSettingsAPI = async () => {
        let res = await getSettingsAction();
        if (res.success) {
            setSettings(res.data);
        }
    }
     
    const getNFTListAPI = async () => {
        let res = await getMarketplaceNFTsAction({ 'user_id': loginData?.id, 'category_ids': [], 'nft_type': '', 'price_type': '','name': SearchFilter,'rarity':RarityIdsFilter });
        if (res.success) {
            setNFTList(res.data);
            setmarketplaceNFTList(res.data);
        }
    }
    
     const getcartcountAPI = async (wallet) => {
        let res = await getcartcountAction({ 'user_id': loginData?.id, 'walletAddress' : wallet });
        if (res.success) {
            setcartcount(res.data);
        }
    }

    const getCategoryAPI = async () => {
        let res = await getCategoryAction();
        if (res.success) {
            setCategory(res.data)
        }
    }

    const nftLike = async (type, item_id) => {
        let res = await nftLikeDislikeAction({ 'type': type, 'item_id': item_id });
        if (res.success) {
            getNFTListAPI();
        }
    }
    
     const NameFilter = async (e) => {
        let res = await getMarketplaceNFTsAction({ 'user_id': loginData?.id, 'category_ids': CategoryIdsFilters, 'nft_type': NFTTypeData, 'price_type': PriceFilterData,'name': SearchFilter,'rarity':RarityIdsFilter });
            if (res.success) {
                if(res.data.length > 0){
                    setNFTList(res.data);
                }else{
                    setNFTList([]);
                }
            }else{
                setNFTList([]);
            }
       
    }
    
   const handleKeyPress = async (event) => {
		console.log(event.key);
     if(event.key === "Enter"){
       let res = await getMarketplaceNFTsAction({ 'user_id': loginData?.id, 'category_ids': CategoryIdsFilters, 'nft_type': NFTTypeData, 'price_type': PriceFilterData,'name': SearchFilter,'rarity':RarityIdsFilter });
            if (res.success) {
                if(res.data.length > 0){
                    setNFTList(res.data);
                }else{
                    setNFTList([]);
                }
            }else{
                setNFTList([]);
            }
     }
 }
    
     const SetNameFilter = async (e) => {
        setSearchFilter(e.target.value);  
         let res = await getMarketplaceNFTsAction({ 'user_id': loginData?.id, 'category_ids': CategoryIdsFilters, 'nft_type': NFTTypeData, 'price_type': PriceFilterData,'name': e.target.value,'rarity':RarityIdsFilter });
            if (res.success) {
                if(res.data.length > 0){
                    setNFTList(res.data);
                }else{
                    setNFTList([]);
                }
            }else{
                setNFTList([]);
            }  
        
    }
    

    const CategoryFilter = async () => {
        let categoryIds = []
        // setNFTList([]);
        $("input[name='category_id[]']:checked").each(function () {
            categoryIds.push(parseInt($(this).val()));
        });

        const catidsArray = categoryIds.filter(function (value) {
            return !Number.isNaN(value);
        });

        setCategoryIdsFilters(catidsArray);
         let res = await getMarketplaceNFTsAction({ 'user_id': loginData?.id, 'category_ids': catidsArray, 'nft_type': NFTTypeData, 'price_type': PriceFilterData,'name': SearchFilter,'rarity':RarityIdsFilter });
            if (res.success) {
                if(res.data.length > 0){
                    setNFTList(res.data);
                }else{
                    setNFTList([]);
                }
            }else{
                setNFTList([]);
            }
        
    }
    
     const RarityFilter = async () => {
        let rarityids = []
        // setNFTList([]);
        $("input[name='rarity[]']:checked").each(function () {
            rarityids.push($(this).val().toString());
        });
		//console.log(rarityids);
       // const rarityidsArray = rarityids.filter(function (value) {
        //    return !Number.isNaN(value);
       // });
		//console.log(rarityidsArray);
        setRarityFilter(rarityids);
        let res = await getMarketplaceNFTsAction({ 'user_id': loginData?.id, 'category_ids': CategoryIdsFilters, 'nft_type': NFTTypeData, 'price_type': PriceFilterData,'name': SearchFilter,'rarity':rarityids });
            if (res.success) {
                if(res.data.length > 0){
                    setNFTList(res.data);
                }else{
                    setNFTList([]);
                }
            }else{
                setNFTList([]);
            }
      
    }
   
    const PriceFilter = async (type) => {
        setPriceFilterData(type);
        let res = await getMarketplaceNFTsAction({ 'user_id': loginData?.id, 'price_type': type, 'nft_type': NFTTypeData, 'category_ids': CategoryIdsFilters ,'name': SearchFilter,'rarity':RarityIdsFilter});
        if (res.success) {
            if(res.data.length > 0){
                setNFTList(res.data);
            }else{
                setNFTList([]);
            }
        }else{
            setNFTList([]);
        }
    }

    const NFTTypeFilter = async (type) => {
        setNFTList([]);
        setNFTTypeData(type)
        let res = await getMarketplaceNFTsAction({ 'user_id': loginData?.id, 'nft_type': type, 'price_type': PriceFilterData, 'category_ids': CategoryIdsFilters });
        if (res.success) {
            setNFTList(res.data);
        }
    }

    return (
        <div>
            <Toaster />
            <Header data={{'cartcount':cartcount}}  />
			<div className="market-place-banner">
			  <h1>MarketPLACE</h1>
			</div>
			<div className="market-place">
			<div className="container">
			<div className="row">
			  <div className="col-12 col-md-3 col-lg-2 px-0 ">
				<div className="left-sidebar">
				  <ul>
					<li className="active"><Link to={`${config.baseUrl}marketplace`} className="">Weapon & Armor Collections</Link></li>
					<li><Link to={`${config.baseUrl}land-marketplace`} className="">Land</Link></li>
					<li><Link to={`${config.baseUrl}licenses-cities`} className="">License</Link></li>
					<li><Link to={`${config.baseUrl}othernfts`} className="">Other NFTs</Link></li>						
					<li><Link to={`${config.baseUrl}mystery-loot-crates`} className="">Mystery Loot Crates</Link></li>						
				  </ul>
				</div>
			  </div>
			  <div className="col-12 col-md-9 col-lg-10 pl-4">
				<div className="row">
				  <div className="col-12 d-flex pro-filter-outer">
					<div className="pro-filter">
					  <div className="dropdown weapon-type">
						  <button className="btn btn-secondary dropdown-toggle" type="button" id="weapon-type" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							Weapon Type <i className="fa-solid fa-chevron-down"></i>
						  </button>
						  <div className="dropdown-menu" aria-labelledby="weapon-type">
						   {Category.map((categoryList) => (
								<>
									<label className="filter-lebel">
									<input onClick={() => CategoryFilter()} type="checkbox" name='category_id[]' value={categoryList.id} /><span className="filter-btn-checkbox"></span>
										{categoryList.name}							
									</label><br />
								</>
							))}
							
					</div>
			</div>
			<div className="dropdown rarity">
			  <button className="btn btn-secondary dropdown-toggle mob-rearty" type="button" id="rarity" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				Rarity <i className="fa-solid fa-chevron-down"></i>
			  </button>
			  <div className="dropdown-menu" aria-labelledby="rarity">
				 <label className="filter-lebel"><input onClick={() => RarityFilter()} type="checkbox" name='rarity[]' value="Common" />
						<span className="filter-btn-checkbox"></span>Common</label><br />
			  <label className="filter-lebel"><input onClick={() => RarityFilter()} type="checkbox" name='rarity[]' value="Epic" />
						<span className="filter-btn-checkbox"></span>Epic</label><br />
			<label className="filter-lebel"><input onClick={() => RarityFilter()} type="checkbox" name='rarity[]' value="Legendary" />
				<span className="filter-btn-checkbox"></span>Legendary</label><br />
			<label className="filter-lebel"><input onClick={() => RarityFilter()} type="checkbox" name='rarity[]' value="Rare" />
				<span className="filter-btn-checkbox"></span>Rare</label><br />
			<label className="filter-lebel"><input onClick={() => RarityFilter()} type="checkbox" name='rarity[]' value="Unique" />
				<span className="filter-btn-checkbox"></span>Unique</label><br />    
			  </div>
			</div>
			<div className="dropdown price-range">
			  <button className="btn btn-secondary dropdown-toggle" type="button" id="price-range" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				Price Range <i className="fa-solid fa-chevron-down"></i>
			  </button>
			  <div className="dropdown-menu" aria-labelledby="price-range">
				<form action="#">
					<div key="3">
						<label className="filter-lebel">
						<input onClick={() => PriceFilter('lowtohigh')} type="radio" name='price_type' value="lowtohigh" />
							<span className="filter-btn-checkbox"></span>Price: Low to high							
						</label><br />

						<label className="filter-lebel"><input onClick={() => PriceFilter('hightolow')} type="radio" name='price_type' value="hightolow" />
							<span className="filter-btn-checkbox"></span>Price: High to low							
						</label><br />
						
					</div>
				</form>
			  </div>
			</div>
			<div className="dropdown nft-order">
			  <button className="btn btn-secondary dropdown-toggle" type="button" id="nft-order" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				NFTs Order <i className="fa-solid fa-chevron-down"></i>
			  </button>
			  <div className="dropdown-menu" aria-labelledby="nft-order">
				<form action="#">
					<div key="3">
						<label className="filter-lebel"><input onClick={() => PriceFilter('newest')} type="radio" name='nft_order' value="newest" />
							<span className="filter-btn-checkbox"></span>Newest							
						</label><br />

						<label className="filter-lebel"><input onClick={() => PriceFilter('oldest')} type="radio" name='nft_order' value="oldest" />
							<span className="filter-btn-checkbox"></span>Oldest							
						</label><br />
						
					</div>
				</form>
			  </div>
			</div>
					</div>
					<div className="pro-search">
					  <form className="search-form">
							 <input type="text" id="search" className="form-control" placeholder="Search (Title Or Token Id)..." onChange={SetNameFilter} name="s" title="Search for" value={SearchFilter} onKeyDown={() => handleKeyPress(this)} />
							   <i className="fa-solid fa-magnifying-glass"></i>           
							</form>							

					</div>
				  </div>
				  <div className="col-12"><div className="pro-line"></div></div>
				  <div className="col-12">
					<h2 className="title">Explore Collection Weapon & Armor NFT ({NFTList.length})</h2>
				  </div>
				  {NFTList.length == 0 ?
					<>
						<h4 className="not-found">No data found!!!</h4>
					</>
					:
					NFTList.slice(0, visible).map((item, index) => (
					<div className="col-lg-4 col-md-6">
						<div className="tab-section-main">
							<div className="tab section">
								<div className="profile-images">								
								</div>
								<div className="Andrew-text">
									<p>{item.owner_address.toString().substring(0, 4) + '...' + item.owner_address.toString().substring(item.owner_address.length - 4)}</p>									
								</div>
								<div className="height-icon">
									<i className="fa-regular fa-heart"></i>
									<p>{item.itemLike}</p>
								</div>
							</div>
							<div className="images-sec">
							 <Link to={`${config.baseUrl}nft-details/` + item.id}>
								<img src={`${config.ipfsUrl + item.image}`}  alt={item.name} className="ht-auto" /></Link>
							</div>
							<div className="section-pistol">
								<h3>
									<Link to={`${config.baseUrl}nft-details/` + item.id}>
										{
										  windowWidth < 1299 ?						
											item.name.toString().substring(0, 15) + '...'
											:
											item.name.toString().substring(0, 26) +
											(item.name.length > 26 ? '...' : '')
										}
									
									</Link>
								</h3>
							</div>
							
							{ item.item_category_id != 8 ?
								<>
							
							<div className="section-ster-icon">
								<div className="tab-icon">
									<p>Damage</p>
									<DamageRating rating={item.Damage} />
								</div>
								{item.item_category_id ===2 ? 
								 <>
								<div className="tab-icon">
									<p>Weapon reach</p>
									<AccuracyRating rating={item.Weapon_reach} />															
								</div>
								<div className="tab-icon">
									<p>Speed rating</p>
									<RateRating rating={item.Speed_Rating} />
								</div>
								</>
								:
								item.item_category_id ===3 || item.item_category_id ===4 ?  
								<>								
								<div className="tab-icon">
									<p>Accuracy</p>
									<AccuracyRating rating={item.Accuracy} />															
								</div>							
								<div className="tab-icon">
									<p>Blast radius</p>
									<RateRating rating={item.Blast_Radius} />
								</div>
								</>
								:
								<>
								<div className="tab-icon">
									<p>Accuracy</p>
									<AccuracyRating rating={item.Accuracy} />															
								</div>							
								<div className="tab-icon">
									<p>Rate of fire</p>
									<RateRating rating={item.Rate_of_Fire} />
								</div>
								</>
								}		
								
								
							</div>
							</>
						:
						<>
						<div className="section-ster-icon mh63">&nbsp;</div>
						</>
						}
							
					{ settings.is_weapon_on_sale ?
						<>	
					<div className="price-section">
						<div className="price">
							<p>Price</p>
							<h3>{parseInt(item.price).toLocaleString('en-US')} SIN</h3>
						</div>
						<div className="price">
								<p>Token ID</p>
								<h3>#{item.token_id}</h3>
							</div>
						<Link to={`${config.baseUrl}nft-details/` + item.id}><button className="tab-btn">Buy</button></Link>
					</div>
					</>
					: item.item_category_id==8 ?
						<>	
						<div className="price-section">
							<div className="price">
								<p>Price</p>
								<h3>{parseInt(item.price).toLocaleString('en-US')} SIN</h3>
							</div>
							<div className="price">
									<p>Token ID</p>
									<h3>#{item.token_id}</h3>
								</div>
							<Link to={`${config.baseUrl}nft-details/` + item.id}><button className="tab-btn">Buy</button></Link>
						</div>
						</>					
					:
					<>	
					<div className="price-section">						
						<div className="price">
								<p>Token ID</p>
								<h3>#{item.token_id}</h3>
							</div>
						<Link to={`${config.baseUrl}nft-details/` + item.id}><button className="tab-btn">Coming Soon</button></Link>
					</div>
					</>
				}
					
					<div className="View-History">
					<Link to={`${config.baseUrl}nft-details/` + item.id+`#history`}><i className="fa-solid fa-clock-rotate-left"></i>View History</Link>
					</div>
					</div>
					</div>
					
					))}
					{
                        visible < NFTList.length &&
                                                      
					<div className="lorn-more col-12 text-center">
					<Link to="#" id="load-more" className="sc-button loadmore fl-button pri-3" onClick={showMoreItems}><button className="tab-last-btn btn-bg-pink px-5">Load More</button></Link>					
					</div>
					  }

					</div>
					</div>
					</div>
					</div>
					</div>
				   

            <Footer />
        </div >
    );
}

export default Marketplace;
